/* ------------- Component: data controller ------------- */

/* critical:start */
/* critical:end */

.c-data-controller { margin-bottom: 20px; padding: 10px 0 10px; text-align: center; font-size: 13px; font-weight: 300; line-height: 19px;
    @media (--min--medium) {
        margin-bottom: 35px; padding: 15px 0 10px;  line-height: 1.1;
    }
    p   { margin: 0 0 10px; }
}

