/* ------------- Component: legal ------------- */

/* critical:start */

/* critical:end */


.c-legal { padding: 30px 0 30px; background: linear-gradient(134.06deg, #FFFFFF 0%, #CBE2EC 100%); overflow: hidden;
    @media (--min--medium) {
        padding: 48px 0 40px;
    }
}

.c-legal--inner { position: relative; overflow: hidden; margin: 0 auto 24px; padding: 0 20px;
    @media (--min--medium) {
        max-width: 748px;
    }
}

.c-legal--heading { color: #2A3644; margin: 0 0 20px; padding: 0 30px; font-size: 18px; line-height: 26px; font-weight: 500; text-align: center;
    @media (--min--medium) {
        font-size: 25px; line-height: 29px; margin: 0 0 40px;
    }
}

.c-legal--box {
    margin: 16px auto;
    background: #fff;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    min-height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 25px 20px;
}
.c-legal--box-icon {
    flex: 0 0 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        display: block;
    }
}

.c-legal--box-content {
    max-width: 540px;
    width: 100%;
    margin-left: 16px;
}

.c-legal--footer {
    padding: 0 20px;
    text-align: center;
    color: #64696D;

    a {
        display: inline-block;
        color: #0245CE;
        font-weight: 500;
        padding: 12px 8px;

        &:before {
            float: left;
            content: '';
            background: url('/wp-content/themes/biuro/i/ico--external.svg') no-repeat 50%;
            height: 18px;
            width: 18px;
            margin-right: 11px;
        }
    }
}
