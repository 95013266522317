/* ------------- Component: newsletter section ------------- */

.c-newsletter {
    padding: 10px 0 45px;
    background: linear-gradient(134.06deg, #CBE2EC 0%, #FFFFFF 100%);

    @media (--min--medium) {
        padding: 70px 0;
    }
}

.c-newsletter--inner {
    display: flex;
    flex-direction: column;
    max-width: 420px;
    margin: 0 auto;
    padding: 0 20px;

    @media (--min--medium) {
        flex-direction: row;
        max-width: 1000px;
        align-items: center;
        padding: 0 41px;
    }
}

.c-newsletter--img {
    position: relative;
    margin: 0 auto;
    width: 151px;
    height: 187px;

    img { height: auto; }

    @media (--max--medium) {
        order: 2;
    }
    @media (--min--medium) {
        width: 278px;
        height: 345px;
        margin: 0 62px 0 0;
    }
}

.c-newsletter--ico {
    position: absolute;
    left: -41px;
    bottom: 41px;

    @media (--max--medium) {
        left: -20px;
        bottom: 20px;
        width: 51px;
        height: 51px;
    }
}

.c-newsletter--content {
    padding: 25px 0;
    @media (--min--medium) {
        flex: 0 1 498px;
    }
}

.c-newsletter--headline {
    margin: 0 0 18px;
    color: #2A3644;
    font-size: 25px;
    font-weight: 500;
    line-height: 34px;

    @media (--min--medium) {
        margin: 0 40px 36px 0;
        line-height: 36px;
    }
}

.c-newsletter--form {
    margin-bottom: 18px;

    @media (--min--medium) {
        display: flex;
        margin-bottom: 36px;
    }
}

.c-newsletter--label {
    flex: 1 1 auto;
    margin-right: 7px;
}

.c-newsletter--input {
    height: 48px;
    width: 100%;
    padding: 0 10px;
    border: 0;
    border-radius: 3px;
    background-color: #fff;
    font-size: 15px;
}

.c-newsletter--privacy {
    max-width: 460px;
    color: #919392;
    font-size: 12px;
    line-height: 18px;
}
