/* ==========================================================================
States:

is-, has-: Signify that the piece of UI in question is currently styled a certain way because of a state or condition. This stateful namespace is gorgeous, and comes from SMACSS. It tells us that the DOM currently has a temporary, optional, or short-lived style applied to it due to a certain state being invoked.
========================================================================== */

@import '_state--nav.css';
@import '_state--header.css';


@media (--max--medium) {
    .is-aside--open { overflow: hidden;
        .l-aside--search { display: block; }
        .l-header { z-index: -1; }
        &:after { content: ''; position: fixed; opacity: 0.97; background-color: #2A3644; top: 0; left: 0; width: 100%; height: 100%; }
        .c-jobs-section--content { max-height: 2000em; }
        .c-jobs-section--expand { display: none; }
    }
    .is-aside--open-additional {
        .l-aside--close { right: 265px; }
        .l-aside--search { right: 0; padding-left: 40px;
            &:before { right: 0; }
        }
        .c-jobs-section--city { display: none; }
    }
    .is-aside--open-city {
        .l-aside--close { left: 265px; }
        .l-aside--search { left: 0; padding-right: 40px;
            &:before { left: 0; }
        }
        .c-jobs-section--additional { display: none; }
    }
}


/*
.l-aside--search {
    @media (--max--medium) {
        display: none; position: fixed; top: 0; right: 0; width: 310px; padding-left: 40px; height: 100%; overflow: auto; z-index: 20;
        &:before { content: ''; position: fixed; top: 0; width: 270px; right: 0; bottom: 0; background: #fff; }
    }
}*/
