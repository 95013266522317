/* ------------- Layout: section ------------- */

/* critical:start */

.l-section {
    padding: 20px 0;
    @media (--min--medium) {
        padding: 30px 0;
    }
}

.l-section-warning {
    background-color: #ffcccb;

    padding: 10px 0;
    @media (--min--medium) {
        padding: 20px 0;
    }
}

.l-section--search-page {
    background-color: #111d1e;
}

.l-section--vdb {
    background-color: #086335;
}

.l-section--front-page {
    margin-bottom: 2px;
    background-color: #fff;
    background-size: 200% !important;
    height: 179px;
    margin-bottom: 185px;

    .l-section--inner {
        max-width: 870px;
        padding-right: 20px;
        padding-left: 20px;
    }
    @media (--max--xx-small) {
        background-image: url(/wp-content/themes/biuro/i/front-page--xx-small.webp);
    }
    @media (--min--xx-small) {
        background-image: url(/wp-content/themes/biuro/i/front-page--x-small.webp);
    }

    @media (--min--small) {
        background-image: url(/wp-content/themes/biuro/i/front-page--small.webp);
        background-size: 150% !important;
    }
    @media (--min--medium) {
        height: 380px;
        margin-bottom: 92px;
    }
    @media (--min--large) {
        background-image: url(/wp-content/themes/biuro/i/front-page.webp);
        background-size: 120% !important;
    }
    @media (--min--x-large) {
        background-size: 100% !important;
    }
    @media (--min--xx-large) {
        background-size: 80% !important;
    }
}

.l-section--sales-page {
    @media (--min--medium) {
        height: 380px;
    }
    .l-section--inner {
        max-width: 870px;
        padding-right: 20px;
        padding-left: 20px;
    }
}

.l-section--inner {
    width: 100%;
    max-width: 1030px;
    margin: 0 auto;
}

.l-section--inner-extended {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
}

.l-section--landing-1 {
    display: flex;
    align-items: flex-end;
    padding: 0 !important;
    background-color: #515e70;
    height: 240px;
    @media (--min--medium) {
        height: 348px;
    }
}

.l-section--recommend {
    display: flex;
    align-items: flex-end;
    padding: 0 !important;
    background-color: #3eb0b1;
    height: 240px;
    @media (--min--medium) {
        height: 355px;
    }
}

.l-section--landing-3 {
    display: flex;
    align-items: flex-end;
    padding: 0 !important;
    background-color: #515e70;
    height: 240px;
    @media (--min--medium) {
        height: 348px;
    }
}

.l-section--landing-4 {
    display: flex;
    align-items: flex-end;
    padding: 0 !important;
    background-color: #515e70;
    height: 240px;
    @media (--min--medium) {
        height: 348px;
    }
}

.l-section--landing-5 {
    display: flex;
    align-items: flex-end;
    padding: 0 !important;
    background-color: #515e70;
    height: 240px;
    @media (--min--medium) {
        height: 348px;
    }
}

.l-section--landing-6 {
    display: flex;
    align-items: flex-end;
    padding: 0 !important;
    background-color: #172333;
    height: 240px;
    @media (--min--medium) {
        height: 348px;
    }
}

.l-section--landing-7 {
    display: flex;
    align-items: flex-end;
    padding: 0 !important;
    background-color: #172333;
    height: 240px;
    @media (--min--medium) {
        height: 348px;
    }
}
/* critical:end */

.l-section {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
}

.l-section--landing-1 {
    background-position: 25% 0;
    @media (--min--medium) {
        background-position: 50% 0;
    }
}

.l-section--recommend {
    background-position: 40% 15%;
    background-size: 220%;
    @media (--min--small) {
        background-position: 50% 15%;
    }
    @media (--min--medium) {
        background-size: cover;
    }
}

.l-section--landing-3 {
    background-position: 50% 25%;
}

.l-section--landing-4 {
    background-position: 50% 20%;
}

.l-section--landing-5 {
    background-position: 50% 20%;
}

.l-section--landing-6 {
    background-position: 50% 20%;
}

.l-section--landing-7 {
    background-position: 50% 20%;
}

.js.no-webp {
    .l-section--search-page {
        background-image: url(/wp-content/themes/biuro/i/search-page.png);
    }
    .l-section--vdb {
        background-image: url(/wp-content/themes/biuro/i/vdb/section.png);
    }
    .l-section--front-page {
        background-image: url(/wp-content/themes/biuro/i/front-page.jpg);
    }
    .l-section--sales-page {
        background-image: url(/wp-content/themes/biuro/i/sales-page.png);
    }
    .l-section--landing-1 {
        background-image: url(/wp-content/themes/biuro/i/sections/landing-1.1.jpg);
    }
    .l-section--recommend {
        background-image: url(/wp-content/themes/biuro/i/sections/recommend.jpg);
    }
    .l-section--landing-3 {
        background-image: url(/wp-content/themes/biuro/i/sections/landing-3.jpg);
    }
    .l-section--landing-4 {
        background-image: url(/wp-content/themes/biuro/i/sections/landing-4.jpg);
    }
    .l-section--landing-5 {
        background-image: url(/wp-content/themes/biuro/i/sections/landing-5.jpg);
    }
    .l-section--landing-6 {
        background-image: url(/wp-content/themes/biuro/i/sections/landing-6.jpg);
    }
    .l-section--landing-7 {
        background-image: url(/wp-content/themes/biuro/i/sections/landing-7.jpg);
    }
}

.js.webp {
    .l-section--search-page {
        background-image: url(/wp-content/themes/biuro/i/search-page.webp);
    }
    .l-section--vdb {
        background-image: url(/wp-content/themes/biuro/i/vdb/section.webp);
    }
    .l-section--front-page {
        @media (--max--xx-small) {
            background-image: url(/wp-content/themes/biuro/i/front-page--xx-small.webp);
        }
        @media (--min--xx-small) and (--max--small) {
            background-image: url(/wp-content/themes/biuro/i/front-page--x-small.webp);
        }
        @media (--min--small) and (--max--large) {
            background-image: url(/wp-content/themes/biuro/i/front-page--small.webp);
        }
        @media (--min--large) {
            background-image: url(/wp-content/themes/biuro/i/front-page.webp);
        }
    }
    .l-section--sales-page {
        background-image: url(/wp-content/themes/biuro/i/sales-page.webp);
    }
    .l-section--landing-1 {
        background-image: url(/wp-content/themes/biuro/i/sections/landing-1.1.webp);
    }
    .l-section--recommend {
        background-image: url(/wp-content/themes/biuro/i/sections/recommend.webp);
    }
    .l-section--landing-3 {
        background-image: url(/wp-content/themes/biuro/i/sections/landing-3.webp);
    }
    .l-section--landing-4 {
        background-image: url(/wp-content/themes/biuro/i/sections/landing-4.webp);
    }
    .l-section--landing-5 {
        background-image: url(/wp-content/themes/biuro/i/sections/landing-5.webp);
    }
    .l-section--landing-6 {
        background-image: url(/wp-content/themes/biuro/i/sections/landing-6.webp);
    }
    .l-section--landing-7 {
        background-image: url(/wp-content/themes/biuro/i/sections/landing-7.webp);
    }
}
