/* ------------- Component: cookies warning ------------- */

/* critical:start */

.c-cookies-warning {
    display: none;
}

/* critical:end */

.c-cookies-warning--content,
.c-cookies-warning--options {
    margin-bottom: 10px;
}

.c-cookies-warning--options {
    display: none;
}

.c-cookies-warning--title {
    margin: 0 0 15px;
    font-size: 21px;
    font-weight: 500;
}

.c-cookies-warning--option {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.c-cookies-warning--option-label,
.c-cookies-warning--option-action {
    display: flex;
    padding: 8px 0;
    align-items: center;
}
.c-cookies-warning--option-label {
    flex: 1 1 calc(100% - 70px);
    font-weight: 600;
}
.c-cookies-warning--option-action {
    flex: 0 0 60px;
    justify-content: flex-end;
}

.c-cookies-warning {
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    width: 100%;
    max-width: 600px;
    padding: 25px 0 10px;
    background: #fff;
    overflow: hidden;
    z-index: 300;
    font-size: 13px;
    line-height: 14px;
    box-shadow: 0 1px 27px -12px #6a7481;
}

.c-cookies-warning--actions {
    margin: 0;
    display: flex;

    .c-btn--main,
    .c-btn--slim {
        margin-right: 10px;
        padding: 7px 12px 6px;
        font-weight: 400;
    }

    .c-btn--slim {
        border: 0;

        &:hover {
            background: none;
            color: var(--color--green-darker);
            text-decoration: underline;
        }
    }
}
