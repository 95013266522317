/* ------------- Tools: font face ------------- */

/*
- move to local host
- https://medium.com/clio-calliope/making-google-fonts-faster-aadf3c02a36d
*/

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Roboto Light'), local('Roboto-Light'),
       url('/wp-content/themes/biuro/fonts/roboto-v19-cyrillic_latin_cyrillic-ext_latin-ext-300.woff2') format('woff2'),
       url('/wp-content/themes/biuro/fonts/roboto-v19-cyrillic_latin_cyrillic-ext_latin-ext-300.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'),
       url('/wp-content/themes/biuro/fonts/roboto-v19-cyrillic_latin_cyrillic-ext_latin-ext-regular.woff2') format('woff2'),
       url('/wp-content/themes/biuro/fonts/roboto-v19-cyrillic_latin_cyrillic-ext_latin-ext-regular.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('/wp-content/themes/biuro/fonts/roboto-v19-cyrillic_latin_cyrillic-ext_latin-ext-500.woff2') format('woff2'),
       url('/wp-content/themes/biuro/fonts/roboto-v19-cyrillic_latin_cyrillic-ext_latin-ext-500.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Roboto Bold'), local('Roboto-Bold'),
       url('/wp-content/themes/biuro/fonts/roboto-v19-cyrillic_latin_cyrillic-ext_latin-ext-700.woff2') format('woff2'),
       url('/wp-content/themes/biuro/fonts/roboto-v19-cyrillic_latin_cyrillic-ext_latin-ext-700.woff') format('woff');
}
