/* ------------- Component: recommend friend ------------- */

/* critical:start */

.c-recommend { display: block; background-color: #3bacad; min-height: 170px; margin: 0 10px 20px; padding: 20px; border-radius: 6px; text-decoration: none;

    @media (--min--medium) {
        margin: 0 0 20px;
    }

    .l-aside--position--wide & {
        @media (--min--medium) {
            margin: 0 -15px 20px;
        }
    }
}

.c-recommend-top {
    display: flex;
    min-height: 62px;
}

.c-recommend--headline { display: block; color: #fff; max-width: 180px; margin-bottom: 20px; font-size: 21px; font-weight: 500; }

.c-recommend--anchor { color: #fff; font-size: 15px; }
/* critical:end */

.c-recommend--headline { position: relative; line-height: 26px; }

.c-recommend--anchor { position: relative; border-bottom: 1px solid #fff; padding: 3px 0; }

.c-recommend {
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        width: 221px;
        height: 253px;
        top: 15%;
        left: 50%;
        background-position: 0 0;
        background-size: cover;
        transform: rotate(-20deg);
        background-repeat: no-repeat;
    }

    strong {
        display: inline-block;
        padding: 3px 5px;
        border-radius: 4px;
        background: #004ed4;
        font-weight: 700;
    }

    &:hover {
        .c-recommend--headline,
        .c-recommend--anchor { color: #9adbdc; }
        .c-recommend--anchor { border-color: #9adbdc; }
    }
}

.no-js,
.js.no-webp {
    .c-recommend {
        &::before { background-image: url(/wp-content/themes/biuro/i/recommend.png); }
    }
    .c-recommend-top {
        &::before { background-image: url(/wp-content/themes/biuro/i/recommend-top.png); }
    }
}

.js.webp {
    .c-recommend {
        &::before { background-image: url(/wp-content/themes/biuro/i/recommend.webp); }
    }
    .c-recommend-top {
        &::before { background-image: url(/wp-content/themes/biuro/i/recommend-top.webp); }
    }
}

.c-recommend-top {
    background: radial-gradient(44.04% 180.85% at 56.55% 59.57%, rgba(87, 181, 181, 0.54) 0%, rgba(90, 204, 204, 0.54) 0.01%, #34B9BA 100%);
    align-items: center;

    &:before {
        transform: rotate(0);
        top: auto;
        right: 35px;
        bottom: 0;
        left: auto;
        width: 130px;
        height: 51px;

        @media (--min--medium) {
            width: 228px;
            height: 89px;
        }
    }

    .c-recommend--headline {
        flex: 1 1 50%;
        max-width: 100%;
        margin: 0;
        padding-right: 20px;
        font-size: 14px;
        line-height: 20px;
        @media (--min--medium) {
            font-size: 18px;
            line-height: 34px;
        }
    }
    .c-recommend--anchor {
        flex: 0 0 auto;
        margin: 0 0 20px;

        @media (--min--medium) {
            margin: 0 240px 0 0;
        }
    }
}
