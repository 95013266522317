/* ------------- Component: nav ------------- */

/* critical:start */

.c-nav          { }

.c-nav--main { display: flex; font-size: 14px;
    @media (--max--medium) {
        margin: 85px 0 0; padding: 0 20px; width: 100%; justify-content: space-between;
    }
}

.c-nav--sub                      { display: none; }

.c-nav--main-item        { position: relative;  z-index: 500;
    &:first-child {
        @media (--max--medium) {
            text-align: left;
        }
    }
    @media (--max--medium) {
        position: relative; flex: 0 1 50%; text-align: right; overflow: hidden;
    }
    @media (--min--medium) {
        height: 70px; margin-right: 26px; padding: 23px 0 0;
    }
}

.c-nav--main-anchor    { display: block; padding: 5px 0 4px; font-weight: 500; color: #fff; text-decoration: none;
    @media (--min--medium) {
        color: var(--color--blue-dark);
    }
}
/* critical:end */

.c-nav--main-anchor    { display: block; padding: 5px 0 4px; text-transform: uppercase; border-bottom: 2px solid transparent;  font-weight: bold; letter-spacing: 1px; opacity: 0.6;
    &:hover { opacity: 1; }
}

.c-nav--sub                    {
    @media (--max--medium) {
        margin: 20px 0;
    }
    @media (--min--medium) {
        position: absolute; top: 100%;
    }
}
.c-nav--sub-item            {
    @media (--min--medium) {
        margin-right: 46px;
    }
}
.c-nav--sub-anchor        { display: block; padding: 16px 0 17px; color: #fff; text-transform: uppercase; white-space: nowrap; text-decoration: none; font-weight: bold; letter-spacing: 1.08px; line-height: 1;
    &:hover { color: var(--color--green); }

    @media (--max--medium) {
        overflow: hidden; text-overflow: ellipsis;
    }
}

.c-nav--footer    { font-weight: 300;
    a                       { text-decoration: none;
        &:hover             { text-decoration: underline; }
    }
}
.c-nav--region    {  }

/* critical:start */
.c-nav--lang-wrap   { width: 74px; height: 32px;
    @media (--max--medium) {
        position: absolute; top: 20px; left: 20px;
    }
    @media (--min--medium) {
        position: relative; width: 70px;
    }
}
/* critical:end */
.c-nav--lang--is-open  {
    @media (--max--medium) {
        background-color: #1D2A3A; opacity: 0.97; z-index: 800; box-shadow: 0 0 20px 60px #1d2a3a
    }
    .c-nav--lang-item     {
        &:nth-child(n+2)    { display: block; }
    }
}

.c-nav--lang        { position: absolute; top: 0; left: 0; width: 100%; border-radius: 3px; z-index: 20; font-weight: 500;
    @media (--min--medium) {
         background: #fff;
    }
}

/* critical:start */
.c-nav--lang-item     { padding: 6px 0 6px;
    &:nth-child(n+2)    { display: none; }
}
/* critical:end */
.c-nav--lang-anchor     { padding-left: 34px; text-decoration: none; display: block; color: #fff; font-size: 14px; line-height: 24px; background-repeat: no-repeat; background-position: 6px 0; background-size: 20px 20px; font-weight: 400;
    @media (--min--medium) {
         color: #2A3644; font-weight: 500;
     }

    &:hover             { text-decoration: underline; }
    svg                                 { float: right; margin: 9px 6px 0 0; }
}

.c-nav--lang-anchor--active {
    &:hover             { text-decoration: none; cursor: pointer;}
}

.c-nav--lang-anchor-lt { background-image: url(/wp-content/themes/biuro/i/lang/lt.png); }
.c-nav--lang-anchor-en { background-image: url(/wp-content/themes/biuro/i/lang/en.png); }
.c-nav--lang-anchor-de { background-image: url(/wp-content/themes/biuro/i/lang/de.png); }
.c-nav--lang-anchor-ru { background-image: url(/wp-content/themes/biuro/i/lang/ru.png); }
.c-nav--lang-anchor-lv { background-image: url(/wp-content/themes/biuro/i/lang/lv.png); }
.c-nav--lang-anchor-et { background-image: url(/wp-content/themes/biuro/i/lang/et.png); }
