/* ------------- Component: tabs ------------- */

/* critical:start */

/* critical:end */

.c-tabs {  }

.c-tabs--nav {
    display: flex;
    box-shadow: 0 1px 21px rgba(143, 147, 152, 0.2);
    background: #fff;
    margin: 0 -10px 25px;
    overflow: auto;

    @media (--min--medium) {
        margin: 0 -10px 25px;
    }
}

.c-tabs--nav-item {
    flex: 1 1 1%;
}

.c-tabs--nav-link {
    display: block;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #828282;
    padding: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
        background-color: #f0f0f0;
    }

    &[aria-selected="true"] {
        background: #1fb299;
        font-weight: 500;
        color: #fff;

        &:hover {
            background: #1fb299;
        }
    }

    @media (--min--medium) {
        font-size: 16px;
        padding: 20px 12px;
    }
}

.c-tabs--content {
    background: #fff;
    box-shadow: 0 1px 21px rgba(143, 147, 152, 0.2);

    &[aria-selected] {
        display: block;
    }
}

.c-tabs--headline {
    padding: 20px;
    background: linear-gradient(134.06deg, #fff -2.85%, rgba(203, 226, 236, 0.42) 102.71%);
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    @media (--min--medium) {
        padding: 34px 41px;
    }
}
