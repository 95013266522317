/* ------------- Component: job ------------- */

/* critical:start */

.c-job--title   { margin: 0 0 15px; padding: 0; color: #2A3644; font-weight: 500; font-size: 22px; line-height: 29px;
    @media (--min--medium) {
        font-size: 30px; line-height: 39px; margin: 0 0 40px;
    }
}

.c-job--content { line-height: 25px; color: #2A3644;
    @media (--max--medium) {
        font-size: 15px;
    }
    h3 { color: #069980; font-size: 19px; font-weight: bold; margin: 0 0 3px; padding: 0;
        @media (--max--medium) {
             font-size: 17px;
        }
        &:nth-child(n+1) { margin-top: 30px; }
    }

    ul { margin: 0 0 1.6rem; padding: 0; list-style: none; }

    li {
        &:before { content: ''; display: inline-block; width: 14px; height: 8px; margin: 0 5px 1px 0; background: url(/wp-content/themes/biuro/i/ico--job-arrow.svg); }
    }
}

.c-job--action { padding: 30px 0; display: flex;
    @media (--max--medium) {
        display: none;
    }
}

.c-job--action-aside {}

/* critical:end */

.c-job-facebook { max-width: 320px; min-height: 180px; margin: 0 auto; overflow: hidden; background: #f4f5f7; color: #fff; text-shadow: 0 2px 4px rgba(0, 0, 0, .9);
    a { text-decoration: none; font-size: 18px; font-weight: 500; line-height: 1.358; }
    @media (--max--medium) {
        padding-bottom: 40px;
    }
}

.c-job-facebook--wide {
    max-width: 340px;
    min-height: 190px;
}
