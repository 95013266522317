/* ------------- Component: awesomplete ------------- */

/* critical:start */

/* critical:end */

.c-404 { text-align: center; padding: 200px 0; }

.c-404--heading { height: 128px; margin: 0; padding: 0; color: #069980; font-size: 109px; font-weight: 500; line-height: 128px;
    span {
        background: -webkit-linear-gradient(138.69deg, #2fb8a1, #4eacd9 78%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.c-404--message { margin-bottom: 40px; }
