/* ------------- Component: social ------------- */

/* critical:start */

/* critical:end */

.c-switch {
    position: relative;
}

.c-switch--label {
    cursor: pointer;
    text-indent: -9999px;
    width: 52px;
    height: 32px;
    background: #d7d8dc;
    display: block;
    border-radius: 32px;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 4px;
        left: 4px;
        width: 24px;
        height: 24px;
        background: #fff;
        border-radius: 24px;
        transition: 0.1s;
    }

    &:active:after {
        width: 28px;
    }
}

.c-switch--checkbox {
    position: absolute;
    height: 0;
    width: 0;
    visibility: hidden;

    &:checked + label {
        background: #1ea88e;

        &:after {
            left: calc(100% - 4px);
            transform: translateX(-100%);
        }
    }

    &:disabled + label {
        pointer-events: none;
        background: #0a3a31;
        opacity: 0.7;

        &:after {
            opacity: 0.8;
        }
    }
}
