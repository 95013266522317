/* ------------- Component: jobs section ------------- */

/* critical:start */
.c-jobs-section { margin: 0 20px 20px 0; padding: 28px 28px 23px; }
/* critical:end */



.c-jobs-section { border-radius: 3px; background-color: #ffffff;
    @media (--max--medium) {
        border-radius: 0; margin: 0; padding-bottom: 0; position: relative;
    }
}

/* critical:start */
.c-jobs-section--heading { margin: 0 0 15px; }
/* critical:end */


.c-jobs-section--heading {  padding: 0; color: #2A3644; font-size: 18px; font-weight: 500; line-height: 21px; }

.c-jobs-section--content { overflow: hidden; }

.c-jobs-section--is-closed {
    .c-jobs-section--content { max-height: 100px; }
}

/* critical:start */
    .c-jobs-section--list { margin: 0; padding: 0; list-style: none; }
/* critical:end */

.c-jobs-section--item { /* line-height: 33px; */ }

.c-jobs-section--item-active { position: relative;
    &:after { content: '\2713'; position: absolute; top: 6px; right: 0; color: #1FB299;  }
    .c-jobs-section--anchor { color: #1FB299; padding-right: 14px; }
}

.c-jobs-section--anchor { display: block; color: #939393; font-size: 14px; text-decoration: none; padding: 7px 0; line-height: 19px;
    &:hover     { text-decoration: underline; }
}

.c-jobs-section--expand { display: block; color: #004ED4; font-size: 14px; font-weight: 500; margin-top: 10px; padding: 5px 0; text-decoration: none; }
