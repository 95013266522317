/* ------------- Component: membership ------------- */

/* critical:start */

/* critical:end */

.c-membership { background: #fff; margin-bottom: 40px;
    td {
        @media (--max--x-small) {
            display: block; padding: 10px 20px;
        }
        @media (--min--x-small) {
            border-bottom: 1px solid #f8f8f8;
        }
    }
}
.c-membership--col-1 {
    @media (--min--x-small) {
        width: 205px; padding: 25px 45px 25px 30px;
    }
}

.c-membership--col-2 { border-bottom: 1px solid #f8f8f8;
    @media (--min--x-small) {
        padding: 25px 30px 25px 0;
    }
}


