/* ------------- Component: awesomplete ------------- */

/* critical:start */

/* critical:end */

.awesomplete [hidden] {
    display: none;
}

.awesomplete .visually-hidden {
    position: absolute;
    clip: rect(0, 0, 0, 0);
}

.awesomplete {
    /*display: inline-block;*/
    position: relative;
}

.awesomplete > input {
    display: block;
}

.awesomplete > ul {
    position: absolute;
    left: 0;
    z-index: 1;
    min-width: 100%;
    box-sizing: border-box;
    list-style: none;
    padding: 0;
    margin: 0;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2);
}

.awesomplete mark {
    background: none;
}

.awesomplete > ul:empty {
    display: none;
}
