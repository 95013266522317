/* ------------- Component: values ------------- */


.c-values { background: linear-gradient(138.69deg, #4EACD9 0%, #2FB8A1 100%); color: #fff; }


/* critical:start */
.c-values--inner { @extend .l-inner-small; padding-top: 20px; padding-bottom: 20px;
    @media (--max--medium) {
        max-width: 400px;
    }
    @media (--min--medium) {
         display: flex; flex-wrap: wrap; justify-content: space-between; padding-top: 60px; padding-bottom: 40px;
    }
}

.c-values--section  { max-width: 250px;
    @media (--min--medium) {
        flex: 0 0 40%; max-width: 280px;
    }
}

.c-values--heading { min-height: 34px; font-size: 35px; line-height: 41px;
    @media (--min--medium) {
        min-height: 66px; font-size: 45px; line-height: 53px;
    }
}

/* critical:end */

.c-values--heading { display: inline-block; min-width: 139px; margin: 0 0 18px; padding: 0 10px 0 0; border-bottom: 4px solid #fff; font-weight: bold; color: #F8F8F8; }

.c-values--content { display: block; min-height: 67px; margin: 0 0 20px; padding: 0; font-size: 15px; line-height: 26px; font-weight: 500;
    @media (--min--medium) {
        min-height: 80px; line-height: 30px;
    }
}
