/* ------------- State: nav ------------- */

/* critical:start */

/*.c-svg--toggler-close   { display: none; margin-right: -28px; }*/

/* critical:end */

/*.is-nav--open       {
    & .l-nav                    { transform: translateX(0); pointer-events: auto; }
    & .c-svg--toggler-open      { display: none; }
    & .c-svg--toggler-close     { display: block; }
}
*/


.is-nav--main-item-active   { position: relative;
    .c-nav--sub { display: block;
        @media (--min--medium) {
            display: flex;
        }
    }
 }

 .is-nav--main-anchor-active  {  opacity: 1;
    @media (--max--medium) {
        border-bottom-color: #fff;
    }
    @media (--min--medium) {
        border-bottom-color: var(--color--blue-dark);
    }
 }
 .is-nav--sub-anchor-active     { color: #19C5A7; }


.is-nav--open {
    @media (--max--medium) {
        overflow: hidden;

        .l-nav--wrap { display: flex; }
    }
}
