/* ------------- Component: footer sections ------------- */

/* critical:start */
.c-footer-sections {
    @media (--min--medium) {
        display: flex;
    }
}

.c-footer-section {
    @media (--min--medium) {
        flex: 10 0 15%;
    }
    &:nth-child(1) { flex: 5 0 15%; }
    &:nth-child(4) { flex: 1 0 15%; }
}
/* critical:end */

.c-footer-sections {
    @media (--min--medium) {
        flex-wrap: wrap;
    }
    @media (--max--medium) {
        text-align: center; font-size: 13px;
    }
}

.c-footer-section { line-height: 36px; margin: 0 0 18px;
    @media (--min--medium) {
        line-height: 25px; margin: 0 0 30px;
    }
    h4  { margin: 0; padding: 0;  }
}

.c-footer-section--heading { margin: 0; font-weight: 500; }


