/* ------------- Elements: anchor ------------- */

/* critical:start */
a   { color: inherit; }
/* critical:end */

a   {
    &:hover     { text-decoration: none; }
}

@media (--min--x-large) {
    [href^="tel"]   { pointer-events: none; text-decoration: none; color: inherit; }
}
