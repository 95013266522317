/* ------------- Util: alignment ------------- */

/**
 * Content alignment
 *
 * @atom Alignment
 * @section Utils > Alignment
 * @markup
 *  <div>.u-align--top</div>
 *  <div>.u-align--middle</div>
 *  <div>.u-align--bottom</div>
 *  <div>.u-align--left</div>
 *  <div>.u-align--center</div>
 *  <div>.u-align--justify</div>
 *  <div>.u-align--right</div>
 */
/*
.u-align--top        { vertical-align: top; }

.u-align--middle     { vertical-align: middle; }

.u-align--bottom     { vertical-align: bottom; }

.u-align--left       { text-align: left; }
*/
.u-align--center     { text-align: center; }
/*
.u-align--justify    { text-align: justify; }

.u-align--right      { text-align: right; }
*/
