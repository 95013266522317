/* ------------- Component: accordion ------------- */

/* critical:start */

/* critical:end */

.c-accordion--section { background: #fff; margin-bottom: 55px; }

.c-accordion--header { display: flex; padding: 16px 10px; color: #2A3644; align-items: center; cursor: pointer; user-select: none; text-decoration: none;
    @media (--min--medium) {
        padding: 26px 30px;
    }
    svg { margin-right: 14px; flex: 0 0 16px; transform: rotate(0deg); transition: transform .22s linear; transform-origin: 50% 50%;
        @media (--min--medium) {
            margin-right: 24px;
        }
    }
    span  { font-size: 16px; line-height: 29px; font-weight: bold; line-height: 19px; }
}

.c-accordion--header--is-expanded {
    svg { transform: rotate(225deg); }
}

.c-accordion--content { padding: 0 20px 0 40px; border-bottom: 1px solid #f8f8f8; transition: height 0.22s linear; overflow: hidden;
    @media (--min--medium) {
        padding: 0 30px 0 70px;
    }

    li + li { margin-top: 10px; }
}

.c-accordion--content--is-collapsed { height: 0; }
