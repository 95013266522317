/* ------------- Component: breadcrumbs ------------- */

/* critical:start */

.c-breadcrumbs  { display: flex; list-style: none; margin: -5px 0 10px; padding: 0; width: 100%; font-size: 13px; height: 20px; color: #6f7479; overflow: hidden;
    @media (--min--medium) {
        margin: -40px 0 0; height: 40px;
    }
    li { overflow: hidden; flex: 0 0 auto;
        &:last-child { flex: 1 1 auto; }
    }
    a { display: block; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; text-decoration: none;
        &:hover { text-decoration: underline; }
    }
}

/* critical:end */

.c-breadcrumb--sep { padding: 0 7px; }

.c-breadcrumb--active { color: #069980; }
