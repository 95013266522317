/* ==========================================================================
Main:

js-: Signify that this piece of the DOM has some behaviour acting upon it, and that JavaScript binds onto it to provide that behaviour. If you’re not a developer working with JavaScript, leave these well alone.

@md: These suffixes take the format @<breakpoint>, and tell us this class when at this size:
    This means min-width:
    @xxx-sm, @xx-sm, @x-sm, @sm, @md, @lg, @x-lg

    This means max-width:
    @xxx-sm--max, @xx-sm--max, @x-sm--max, @sm--max, @md--max, @lg--max, @x-lg--max

    This means min-width && max-width:
    @xxx-sm--sm, @x-sm--md, @lg--x-lg

    This means print or screen:
    @print, @screen

qa-: Signify that a QA or Test Engineering team is running an automated UI test which needs to find or bind onto these parts of the DOM. Like the JavaScript namespace, this basically just reserves hooks in the DOM for non-CSS purposes.
========================================================================== */

@import '01-settings/__settings.css';
@import '02-tools/__tools.css';
@import '03-generic/__generic.css';
@import '04-elements/__elements.css';
@import '05-layout/__layout.css';
@import '06-objects/__objects.css';
@import '07-components/__components.css';
@import '08-states/__states.css';
@import '09-themes/__themes.css';
@import '10-trumps/__utils.css';

input::-webkit-calendar-picker-indicator {
    display: none;
}

.tmp-contacts {
    img {
        display: none;
    }
    ul {
        padding-left: 5px;
    }
}

.c-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    div {
        flex: 0 0 21%;
        border: 1px solid gray;
        padding: 1em;
        margin-bottom: 1em;
        text-align: center;
    }
}

.c-fixed-footer {
    @media (--max--medium) {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        background: rgba(255, 255, 255, 0.85);
        padding: 20px 20px 0;
        box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.45);
    }
}

.biuro-ti-img {
    display: none;
}

.grecaptcha-badge {
    pointer-events: none;
    visibility: hidden;
}
