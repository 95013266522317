/* ------------- Component: services ------------- */

/* critical:start */

.c-services   { @extend .l-inner-small; padding: 20px;
   @media (--min--medium) {
        display: flex; flex-wrap: wrap; align-items: center; padding-top: 64px; padding-bottom: 64px; justify-content: space-between;
    }
}

.c-services--item  { position: relative; height: 245px;
    @media (--max--medium) {
        max-width: 380px; margin: 0 auto 20px;
    }
    @media (--min--medium) {
        flex: 0 0 253px;
    }
}

.c-services--toggle  { display: none; }

/* critical:end */

.c-services--item-inner  { position: absolute; top: 0; left: 0; width: 100%; height: 245px; border-radius: 3px; background-color: #FFFFFF;  box-shadow: 2px 2px 31px 0 rgba(238,238,238,0.5); }

.c-services--toggle  { display: block; position: absolute; left: 0; width: 100%; bottom: 0; padding: 10px 0; text-align: center; cursor: pointer; color: #1FB299; }

.c-services--toggle-up { display: none; }

.c-services--is-open { height: auto; z-index: 200;
    .c-services--toggle-down { display: none;  }
    .c-services--toggle-up { display: inline; }
    .c-services--content { display: block; }
}

.c-services--ico  { height: 56px; width: 56px; background-color: #E1FFFA; border-radius: 50%; margin: 34px auto 22px;  }

.c-services--heading  { min-height: 44px; padding: 0 20px; color: #1FB299; font-size: 15px; font-weight: bold; line-height: 18px; text-align: center;
    @media (--min--medium) {
        min-height: 44px;
    }
}

.c-services--sub-heading  { min-height: 27px; margin: 0; padding: 0 10px; color: #2A3644; font-size: 14px; font-weight: bold; line-height: 25px; text-align: center; }

.c-services--content { display: none;
    ul { margin: 0; padding: 0 0 40px; list-style: none; width: 100%; border-radius: 3px; background-color: #FFFFFF;  }
    li { text-align: center; color: #6C7683; font-size: 14px; font-weight: 500; line-height: 25px; text-align: center; }
}

.c-services--header {
    color: #2a3644;
    margin: 40px 0 36px;
    padding: 0;
    font-size: 25px;
    font-weight: 500;
    line-height: 29px;
    text-align: center;
}

.c-services--wide {
    max-width: 1240px;
    padding-top: 0;
}

.c-services--item--extended {
    height: 214px;
    border: 2px solid #fff;
    cursor: pointer;
    margin-bottom: 23px;

    @media (--max--medium) {
        max-width: 640px;
    }

    @media (--min--medium) {
        flex: 0 0 220px;
    }

    &:hover {
        border-color: #1fb299;
    }

    &[aria-selected="true"] {
        border-color: #1fb299;

        .c-services--toggle-down {
            display: none;
        }

        .c-services--toggle-up {
            display: inline;
        }
    }
}

.c-services--item-inner--extended {
    height: 210px;
    user-select: none;
}

.c-services--details {
    display: flex;
    flex: 0 0 100%;
    max-width: 1200px;
    margin: 0 auto 23px;
    box-shadow: 2px 2px 31px rgba(238, 238, 238, 0.5);
    border-radius: 3px;
    background: #fff;

    @media (--max--medium) {
        flex-direction: column;
        max-width: 640px;
    }

    @media (--min--medium) {
        order: 10;
    }

    &[hidden] {
        display: none;
    }
}

.c-services--details-image {
    border-radius: 3px 0 0 3px;
    overflow: hidden;
    flex: 0 0 180px;
    background-size: cover;
    background-position: 50% 50%;

    @media (--min--medium) {
        flex: 0 0 300px;
    }
}

.c-services--details-content {
    flex: 1 1 auto;
    padding: 26px 20px;
    font-size: 15px;
    line-height: 22px;

    @media (--min--medium) {
        padding: 36px 32px;
    }

    .wp-block-columns {
        @media (--min--medium) {
            display: flex;
            justify-content: space-between;
        }
    }

    .wp-block-column {
        @media (--min--medium) {
            flex: 0 1 calc(50% - 10px);
        }
    }

    h4 {
        display: inline-block;
        margin-top: 0;
        padding-bottom: 4px;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        border-bottom: 2px solid #000;
        text-transform: uppercase;
    }

    ul {
        list-style: none;
        margin: 15px 0 24px 0;
        padding: 0;
    }

    li {
        position: relative;
        padding: 3px 0 3px 30px;
        color: #475569;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;

        &::before {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.06836 11.7656C6.36133 12.0586 6.85938 12.0586 7.15234 11.7656L15.7656 3.15234C16.0586 2.85938 16.0586 2.36133 15.7656 2.06836L14.7109 1.01367C14.418 0.720703 13.9492 0.720703 13.6562 1.01367L6.625 8.04492L3.31445 4.76367C3.02148 4.4707 2.55273 4.4707 2.25977 4.76367L1.20508 5.81836C0.912109 6.11133 0.912109 6.60938 1.20508 6.90234L6.06836 11.7656Z' fill='%231FB299'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: 50% 50%;
            position: absolute;
            top: 7px;
            left: 0;
            width: 16px;
            height: 16px;
        }
    }
}

.c-services--details-action {
    @media (--max--medium) {
        text-align: center;
    }
}
