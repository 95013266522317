/* ------------- Component: recommend friend ------------- */

/* critical:start */

.c-recommend-friend { padding: 10px 0;
    @media (--max--medium) {
        max-width: 440px; margin: 0 auto;
    }
    @media (--min--medium) {
        padding: 30px 0 40px;
    }
}

.c-recommend-friend--inner { max-width: 1170px; padding: 0 10px; margin: 0 auto;
    @media (--min--medium) {
        display: flex; justify-content: space-between;
    }
}

.c-recommend-friend--content { color: #2A3644; font-weight: 500; line-height: 22px; padding: 15px 0 20px;
    @media (--min--medium) {
          flex: 1 1 40%;  max-width: 500px; line-height: 29px;
    }
    img { max-width: 100%; height: auto; }
    span { display: block; line-height: 22px; overflow: hidden; }
}

/*.c-recommend-friend--img { margin-top: 40px; }*/

.c-recommend-friend--form { max-width: 604px;
    @media (--min--medium) {
        flex: 1 1 40%; margin-top: -200px;
    }
}

/* critical:end */

.c-recommend-friend--gift         { background-color: #004ED4; margin-top: 60px; max-width: 444px; min-height: 115px; border-radius: 6px; color: #FFFFFF; line-height: 20px; font-weight: 400; text-align: center; padding: 0 20px;
    @media (--min--medium) {
        padding: 0 40px; margin-top: 80px; line-height: 24px;
    }
}
.c-recommend-friend--gift-img { position: relative; top: -36px; width: 70px; height: 70px; margin: 0 auto -30px; padding: 20px 0 0; box-shadow: 0 2px 12px 0 rgba(146,141,141,0.5); background-color: #FFFFFF; border-radius: 50%; color: #004ED4; }
