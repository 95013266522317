/* ------------- Component: jobs-manager ------------- */

.c-jobs-manager {
    margin: 0 20px;
    padding: 30px 30px 22px;
    background: linear-gradient(292.9deg, #6bc0d6 19.05%, #99d5e4 95.4%);
    border-radius: 20px 20px 20px 4px;
    color: #fff;

    @media (--min--medium) {
        margin: 0 -15px;
    }
}

.c-jobs-manager--message {
    font-size: 16px;

    h3 {
        margin-bottom: 10px;
        color: inherit;
    }
}

.c-jobs-manager--contacts {
    padding-top: 12px;
    border-top: 1px solid rgba(255, 255, 255, .4);

    ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;
        color: inherit;
    }

    li {
        flex: 1 0 auto;
        padding: 7px 0 7px 30px;
        line-height: 20px;
        font-weight: 700;
    }

    a {
        text-decoration: none;
    }
}

.c-jobs-manager--member {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 60px;
    margin: 0 20px 30px;
    padding-left: 86px;
    justify-content: flex-end;

    @media (--min--xxx-small) {
        padding-left: 120px;
    }

    @media (--min--medium) {
        margin: 0 -15px 30px;
    }
}

.c-jobs-manager--member-photo {
    position: absolute;
    top: -18px;
    left: 0;
    width: 76px;
    height: 76px;
    padding: 3px;
    border-radius: 50%;
    background: rgb(111,193,208);
    background: linear-gradient(128deg, rgba(111,193,208,1) 0%, rgba(31,197,170,1) 100%);

    @media (--min--xxx-small) {
        left: 30px;
    }

    img {
        border-radius: 50%;
    }
}

.c-jobs-manager--member-name {
    margin: 0 0 4px;
    font-size: 14px;
    font-weight: 500;
    color: #4f4f4f;
}

.c-jobs-manager--member-position {
    margin: 0 0 4px;
    font-size: 12px;
    font-weight: 400;
    color: #6f7479;
}
