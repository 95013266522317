
/* ------------- Component: sections ------------- */

/* critical:start */

.c-sections   { max-width: 1000px; margin-right: auto; margin-left: auto; align-items: center; padding: 20px 20px 20px;
    @media (--min--medium) {
        padding: 50px 20px 135px;
    }
}
.c-sections--inner  {
    @media (--min--medium) {
         display: flex; flex-wrap: wrap; justify-content: space-between;
    }
}

.c-sections--item  { position: relative; height: 211px; margin-bottom: 30px;
    @media (--max--medium) {
        max-width: 380px; margin: 0 auto 30px;
    }
    @media (--min--medium) {
         flex: 0 0 300px;
    }
}
/* critical:end */


.c-sections--heading { color: #2A3644; margin: 0 0 40px; padding: 0; font-size: 25px; font-weight: 500; line-height: 29px; text-align: center; }

.c-sections--item-inner  { position: absolute; top: 0; left: 0; width: 100%; height: 211px; padding: 27px 21px; border-radius: 3px; background: #FFFFFF;  box-shadow: 2px 2px 31px 0 rgba(238,238,238,0.5); overflow: hidden; }

.c-sections--toggle  { position: absolute; left: 0; width: 100%; bottom: 0; padding: 15px 0; text-align: center; background: #FFFFFF; border-radius: 0 0 3px 3px; z-index: 60; box-shadow: 0 -37px 24px  0px hsla(0,0%,100%,.78); cursor: pointer; color: #004ED4; }

.c-sections--toggle-up { display: none; }

.c-sections--header { height: 56px; margin-bottom: 20px; overflow: hidden; color: #004ED4; font-size: 15px; font-weight: bold; line-height: 56px; }

.c-sections--ico  { float: left; margin-right: 20px; height: 56px; width: 56px; background-color: #E8F0FF; border-radius: 50%;  }

.c-sections--content  { color: #6f7479;  font-size: 14px; line-height: 20px;
    ul  { margin: 30px 0 0; padding: 0; list-style: none; }
}

.c-sections--is-open { height: auto; z-index: 80;
    .c-sections--toggle { box-shadow: none; }
    .c-sections--toggle-down { display: none;  }
    .c-sections--toggle-up { display: inline; }
}
