/* ==========================================================================
Components:

Specific UI components. This is where majority of our work takes place and our UI components are often composed of Objects and Components
rich design UI styling. Class names here are more specific. This layer usually has the biggest volume
c-: Signify that something is a Component. This is a concrete, implementation-specific piece of UI. All of the changes you make to its styles should be detectable in the context you’re currently looking at. Modifying these styles should be safe and have no side effects.
========================================================================== */

@import 'vendor/popup-bubble.css';
@import 'vendor/site-reviews.css';
@import 'vendor/swiper.css';
@import 'wordpress/page-numbers.css';

@import '_component--404.css';
@import '_component--accordion.css';
@import '_component--awesomplete.css';
@import '_component--breadcrumbs.css';
@import '_component--btn.css';
@import '_component--categories.css';
@import '_component--cookies-warning.css';
@import '_component--contact.css';
@import '_component--contact-landing-1.css';
@import '_component--contact-landing-2.css';
@import '_component--contact-landing-3.css';
@import '_component--contact-landing-4.css';
@import '_component--contact-landing-5.css';
@import '_component--contact-landing-6.css';
@import '_component--contact-landing-7.css';
@import '_component--copyright.css';
@import '_component--data-controller.css';
@import '_component--divisions.css';
@import '_component--feedbacks.css';
@import '_component--footer-action.css';
@import '_component--footer-sections.css';
@import '_component--footer-separator.css';
@import '_component--form.css';
@import '_component--heading.css';
@import '_component--ico.css';
@import '_component--job.css';
@import '_component--jobs.css';
@import '_component--jobs-contacts.css';
@import '_component--jobs-manager.css';
@import '_component--jobs-pagination.css';
@import '_component--jobs-section.css';
@import '_component--legal.css';
@import '_component--logo.css';
@import '_component--members.css';
@import '_component--membership.css';
@import '_component--modal.css';
@import '_component--nav.css';
@import '_component--newsletter.css';
@import '_component--phone.css';
@import '_component--popup.css';
@import '_component--recommend-friend.css';
@import '_component--recommend.css';
@import '_component--sales-page.css';
@import '_component--search.css';
@import '_component--sections.css';
@import '_component--services.css';
@import '_component--share.css';
@import '_component--social.css';
@import '_component--switch.css';
@import '_component--tabs.css';
@import '_component--tooltip.css';
@import '_component--trust.css';
@import '_component--values.css';
@import '_component--vdb.css';

.t-reason {
    padding: 0;
    border: 0;
}
.t-reason td {
    display: block;
    vertical-align: top;
}
.t-reason .t-reason-col-2 h2 {
    padding: 25px 0 13px;
}
.t-reason h2 {
    text-align: center;
}
.t-reason ul {
    padding: 0;
}
@media (--min--medium) {
    .t-reason td {
        display: table-cell;
        width: 50%;
    }
    .t-reason .t-reason-col-1 {
        padding-right: 20px;
        border-right: 1px solid #dfdfdf;
    }
    .t-reason .t-reason-col-2 {
        padding-left: 20px;
    }
    .t-reason .t-reason-col-2 h2 {
        padding: 12px 0 25px;
    }
}

.t-contacts {
    width: 100%;
    height: auto !important;
}
.t-contacts td {
    vertical-align: top;
    display: block;
    width: 100%;
}
.t-contacts p {
    text-align: left;
}
