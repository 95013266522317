/* ------------- Component: page number ------------- */

.page-numbers {
    width: 46px;
    height: 46px;
    background: #fff;
    margin-right: 1px;
    text-decoration: none;
    text-align: center;
    line-height: 46px;
    color: var(--color--green);
    font-size: 16px;
}

.page-numbers:hover {
    background: #149a83;
    background: var(--color--green-darker);
    color: #fff;
}

.page-numbers:hover:after {
    border-color: #fff;
}

.page-numbers.current {
    background: var(--color--green);
    color: #fff;
}

.next {
    position: relative;
    text-indent: -2000em;
    overflow: hidden;
}

.next:after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    margin: -4px 0 0 -4px;
    width: 8px;
    height: 8px;
    border: 1px solid var(--color--green);
    border-width: 1px 1px 0 0;
    transform: rotate(45deg);
}

.prev {
    position: relative;
    text-indent: -2000em;
    overflow: hidden;
}

.prev:after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    margin: -4px 0 0 -4px;
    width: 8px;
    height: 8px;
    border: 1px solid var(--color--green);
    border-width: 1px 1px 0 0;
    transform: rotate(-135deg);
}
