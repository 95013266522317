/* ------------- Component: members ------------- */

/* critical:start */

.c-members {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
}

/* critical:end */

.c-member {
    margin: 0 3px;
    flex: 0 0 159px;
    padding: 20px 0;
}

.c-member--photo {
    display: block;
    height: 110px;
    width: 110px;
    margin: 0 auto 16px;
    border-radius: 50%;
    background: #c4c4c4;
    overflow: hidden;
}

.c-member--info {
    line-height: 22px;
    text-align: center;
    width: 149px;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.c-member--name {
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 500;
    color: #4f4f4f;
}

.c-member--position {
    font-size: 12px;
    font-weight: 400;
    color: #6f7479;
}

