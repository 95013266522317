/* ------------- Component: logo ------------- */

/* critical:start */

.c-logo { display: block; width: 81px; margin-right: 89px; padding: 11px 0; text-decoration: none;  }

.c-logo--svg { display:  block; }

/* critical:end */

.c-logo--motherson {
    display: block;

    @media (--max--medium) {
        margin: 17px 0 0 -55px;
    }
}
