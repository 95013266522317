/* ------------- Component: contact section ------------- */

/* critical:start */

.c-contact-landing-6 { padding: 10px 0;
    @media (--max--medium) {
        max-width: 440px; margin: 0 auto;
    }
    @media (--min--medium) {
        padding: 20px 0;
    }
}

.c-contact-landing-6--inner { max-width: 1000px; padding: 0 10px; margin: 0 auto;
    @media (--min--medium) {
        display: flex; justify-content: space-between;
    }
}

.c-contact-landing-6--content { color: #2A3644; font-weight: 500; padding: 15px 0 20px 15px;
    @media (--min--medium) {
          flex: 1 1 40%;  max-width: 500px; padding: 0 0 0 90px;
    }
    img { max-width: 100%; height: auto; }
    span { display: block; overflow: hidden; line-height: 22px; padding: 2px 0 0 0; }
}

.c-contact-landing-6--img { margin-top: 40px; }

.c-contact-landing-6--form { max-width: 420px;
    @media (--min--medium) {
        flex: 1 1 40%; margin-top: -180px;
    }
}

/* critical:end */

.c-contact-landing-6--aside { padding: 10px 20px; margin: 0 -10px 0 -25px; background: linear-gradient(90deg, #C5F3EC 0%, #E4FAF7 100%);
    @media (--min--medium) {
        width: 549px; margin: 40px 0 0 40px; padding: 10px; border-radius: 33px; overflow: hidden;
    }
    svg { float: left; margin-right: 25px;
        @media (--max--medium) {
            display: none;
        }
    }
    p { overflow: hidden; color: #149183; margin: 0; text-align: center;
        @media (--min--medium) {
            max-width: 300px; padding: 5px 0 0; text-align: left;
        }
    }
}
