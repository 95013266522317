/* ------------- Component: feedbacks ------------- */

/* critical:start */

.c-feedbacks { padding: 30px 0 30px; background: linear-gradient(134.06deg, #FFFFFF 0%, #CBE2EC 100%); overflow: hidden;
    @media (--min--medium) {
        padding: 50px 0 70px;
    }
}

.c-feedbacks--inner { position: relative; overflow: hidden; height: 420px; margin: -20px auto -40px; width: 100%; padding: 20px 10px 0;
    @media (--min--medium) {
        max-width: 720px; padding: 20px 0 0;
    }
}

.c-feedbacks--heading { color: #2A3644; margin: 0 0 20px; padding: 0 30px; font-size: 18px; line-height: 26px; font-weight: 500; text-align: center;
    @media (--min--medium) {
        font-size: 25px; line-height: 29px; margin: 0 0 40px;
    }
}

.c-feedbacks--section    { max-width: 300px; height: 350px; margin: 0 auto; }
.c-feedbacks--section-inner { position: relative; height: 300px !important; max-width: 100%; background-color: #FFFFFF; border-radius: 3px; padding-top: 50px; }


.swiper-slide-active {
    .c-feedbacks--section-inner { box-shadow: -1px 15px 46px 0 rgba(192,210,231,0.5); }
}


/* critical:end */

.c-feedbacks--img { display: block; margin: 0 auto 40px; border-radius: 50%; overflow: hidden; }

.c-feedbacks--img-empty { width: 85px; height: 83px; background: #e8e9ea; }

.c-feedbacks--feedback { margin: 0 auto 10px; max-width: 90%; min-height: 66px; color: #2A3644; font-family: Georgia; font-size: 17px; line-height: 23px; text-align: center; overflow: hidden; width: 100%;
   @media (--min--medium) {
        margin: 0 auto 15px; min-height: 79px; line-height: 27px;
    }
}

.c-feedbacks--name { color: #919191; font-family: Georgia; font-size: 13px; line-height: 17px; text-align: center; padding: 0 15px;
    @media (--min--medium) {
        font-size: 15px; line-height: 21px;
    }
}

.swiper-slide { max-width: 300px; }

.swiper-container-horizontal > .swiper-pagination-bullets { bottom: 0; }

.swiper-pagination-bullet   { width: 9px; height: 9px; background: #D8D8D8; opacity: 1; }

.swiper-pagination-bullet-active { background: #717171; }
