/* ------------- Component: bubble ------------- */

.popup-bubble {
    position: absolute;
    background-color: #fff;
    padding: 5px 12px 4px;
    border-radius: 5px;
    font-size: 15px;
    max-height: 60px;
    font-weight: 500;
    box-shadow: 0 2px 10px 1px rgba(0,0,0,0.5);
    white-space: nowrap;

    &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
    }

    &:hover { z-index: 10; }
}

.popup-bubble--top {
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);

    &::after {
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0);
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #fff;
    }
}

.popup-bubble--right {
    top: 4px;
    left: 6px;
    transform: translate(0, -50%);

    &::after {
        top: 50%;
        left: 0;
        transform: translate(-100%, -50%);
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: 6px solid #fff;
    }
}

.popup-bubble--bottom {
    top: 0;
    left: 0;
    transform: translate(-50%, 6px);

    &::after {
        top: -6px;
        left: 50%;
        transform: translate(-50%, 0);
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #fff;
    }
}

.popup-bubble--left {
    top: 4px;
    right: 6px;
    transform: translate(0, -50%);

    &::after {
        top: 50%;
        right: 0;
        transform: translate(100%, -50%);
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid #fff;
    }
}

.popup-bubble-anchor {
    position: absolute;
    width: 1px;
    bottom: 6px;
    left: 0;
}

.popup-container {
    cursor: auto;
    height: 0;
    position: absolute;
    width: 200px;
}

.l-map--cities--panevezys {
    .popup-bubble {
        background: #000;
        box-shadow: 0 1px 15px rgba(124, 124, 124, 0.5);
        border-radius: 8px;
        color: #fff;

        &::after { display: none; }
    }
}
