/* ------------- Component: contact section ------------- */

/* critical:start */

.c-contact-landing-5 { padding: 10px 0;
    @media (--max--medium) {
        max-width: 440px; margin: 0 auto;
    }
    @media (--min--medium) {
        padding: 20px 0;
    }
}

.c-contact-landing-5--inner { max-width: 1000px; padding: 0 10px; margin: 0 auto;
    @media (--min--medium) {
        display: flex; justify-content: space-between;
    }
}

.c-contact-landing-5--content {
    color: #2a3644;
    font-weight: 500;
    padding: 15px 0 20px 15px;

    @media (--min--medium) {
        flex: 1 1 40%;
        max-width: 510px;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    span {
        display: block;
        overflow: hidden;
        line-height: 22px;
        padding: 2px 0 0 0;
    }

    h2 {
        font-weight: bold;
        font-size: 24px;
        line-height: 34px;
        color: #000;
    }
}

.c-contact-landing-5--content-inner {
    @media (--min--medium) {
        columns: 2;
    }

    p {
        @media (--min--medium) {
            width: 200px;
            height: 50px;
        }
    }
}

.c-contact-landing-5--content-inner,
.c-contact-landing-5--content-inner-alt {
    p {
        @media (--min--medium) {
            padding: 15px 0;
        }

        font-size: 16px;
        line-height: 20px;
    }

    svg {
        color: #da2128;
    }
}

.c-contact-landing-5--content-inner-alt {
    max-width: 380px;
    padding-top: 10px;
    font-weight: 900;

    b {
        color: #da2128;
    }
}

.c-contact-landing-5--img { margin-top: 40px; }

.c-contact-landing-5--form { max-width: 390px;
    @media (--min--medium) {
        flex: 1 1 40%; margin-top: -244px;
    }
}

/* critical:end */

.c-contact-landing-5--aside {
    padding: 10px 15px;
    background: #ececec;
    overflow: hidden;
    margin: 20px 0 10px;

    svg {
        float: left;
        margin-right: 15px;
        @media (--min--medium) {
            margin-right: 25px;
        }
    }

    p {
        overflow: hidden;
        color: #787878;
        margin: 4px 0 0;

        @media (--min--medium) {
            max-width: 300px;
            text-align: left;
            font-size: 16px;
            line-height: 23px;
            margin: 0;
        }
    }

    @media (--min--medium) {
        margin: 0;
        padding: 25px;
    }
}
