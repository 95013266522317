/* ------------- Layout: header ------------- */

/* critical:start */

.l-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 300;
    height: 70px;
    background: #fff;

    @media (--min--medium) {
        margin-bottom: 47px;

        &::before {
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 47px;
            background: var(--color--blue-dark);
        }
    }
}

.l-header--langing {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    z-index: 300;
}

/* critical:end */

.l-header {
    .customize-support & { top: 32px; }

    @media (--max--medium) {
        transition-duration: .5s;
        transition-timing-function: cubic-bezier( 0.215, 0.610, 0.355, 1.000 );
        transition-property: transform;
    }
}
