/* ------------- Component: tooltip ------------- */

/* critical:start */

.c-tooltip { position: absolute; pointer-events: none; opacity: 0;  }

/* critical:end */

.c-tooltip { top: 46px; right: 0;  max-width: 80vw; padding: 16px 10px 17px; border-radius: 3px; background-color: #fff; box-shadow: -2px 0 14px 0 rgba(217,214,214,0.5); white-space: nowrap; z-index: 10; transition: opacity 0.22s ease-in-out;
    @media (--min--xx-small) {
        left: 50%; transform: translate(-50%, 0); right: auto; padding: 16px 28px 17px; top: 50px;
    }
    &:before { content: ''; position: absolute; top: -6px; right: 20px; border-bottom: 6px solid #fff; border-left: 6px solid transparent; border-right: 6px solid transparent;
        @media (--min--xx-small) {
            right: auto; left: 50%; transform: translate(-50%, 0);
        }
    }
}
