/* ------------- Component: trust ------------- */

/* critical:start */

.c-trust    { background: linear-gradient(134.06deg, #70B7D5 0%, #7BCBCF 100%); }

.c-trust--inner { @extend .l-inner-small; align-items: center; padding-top: 25px; padding-bottom: 45px;
    @media (--min--medium) {
        display: flex; flex-wrap: wrap; padding-top: 64px; padding-bottom: 64px;
    }
}

.c-trust--heading   { flex: 1 1 auto; margin: 0 0 35px; color: #fff;
    @media (--min--medium) {
        margin: 0;
    }
    @media (--max--medium) {
        text-align: center; font-size: 18px;
    }
}

.c-trust--logos { flex: 10 1 auto; display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center;
    @media (--min--medium) {
        justify-content: space-around;
    }
}

/* critical:end */


.c-trust--img-svyturys {
    @media (--max--medium) {
        width: 76px; height: 23px;
    }
}
.c-trust--img-dpd {
    @media (--max--medium) {
        width: 53px; height: 23px;
    }
}
.c-trust--img-pergale {
    @media (--max--medium) {
        width: 72px; height: 16px;
    }
}
.c-trust--img-maxima {
    @media (--max--medium) {
        width: 74px; height: 17px;
    }
}

.c-trust--img-pasts {
    @media (--max--medium) {
        width: 85px; height: 20px;
    }
}
.c-trust--img-dhl {
    @media (--max--medium) {
        width: 91px; height: 13px;
    }
}
.c-trust--img-staburadze {
    @media (--max--medium) {
        width: 90px; height: 27px;
    }
}
.c-trust--img-cido {
    @media (--max--medium) {
        width: 51px; height: 33px;
    }
}
.c-trust--img-jysk {
    @media (--max--medium) {
        width: 72px; height: 33px;
    }
}

.c-trust--img-itella {
    @media (--max--medium) {
        width: 60px; height: 38px;
    }
}
.c-trust--img-saku {
    @media (--max--medium) {
        width: 56px; height: 41px;
    }
}
.c-trust--img-ipa {
    @media (--max--medium) {
        width: 54px; height: 24px;
    }
}
.c-trust--img-leibur {
    @media (--max--medium) {
        width: 50px; height: 42px;
    }
}
.c-trust--img-maxima-ee {
    @media (--max--medium) {
        width: 86px; height: 18px;
    }
}
