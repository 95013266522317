/* ------------- Component: jobs-contacts ------------- */

.c-jobs-contacts {
    margin: 0 0 30px;

    @media (--max--medium) {
        margin: 0 10px 30px;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        padding: 7px 0 7px 30px;
        line-height: 20px;
    }

    a {
        overflow: hidden;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

/* critical:start */

[href="https://maps.google.com/?q="] {
    display: none !important;
}

/* critical:end */
