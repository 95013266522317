/* Css for bubbles start */
.scroll_bar_parent {
    position: relative;
}
.active {
    background: #1ea88e !important;
}

.scrool_bar {
    display: flex;
    flex-direction: column;
    gap: 7px;
    position: fixed;
    top: 30%;
    left: 14%;
    z-index: 4;
}

.scrool_bar a {
    width: 8px;
    height: 8px;
    background: #d9d9d9;
    border-radius: 50%;
    display: block;
}
/* css Dor bubbles End */

button {
    cursor: pointer;
}

.main_container {
    overflow: hidden;
    background-color: #f7f7f7;
    box-sizing: border-box;

    div,
    span {
        box-sizing: border-box;
    }
}

.flexible_row {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    width: 936px;
    height: 390px;
    gap: 10px;
    margin: 1rem auto;
}
.swiper-pagination {
    transform: rotate(90deg);
}
.sweper-pagination-parent {
    position: relative;
}
.swiper-pagination {
    transform: rotate(90deg);
    top: 50%;
    left: 14%;
}
.fl_slider_warp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.fl_slider {
    display: flex;
    gap: 15px;
    max-width: 100% !important;
}

.shadow_container5 {
    width: 664px;
    height: 368px;
    position: relative;
}

.fl_left_side {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 32px 20px;
    position: absolute;
    z-index: 2;
    background: #fff;
}

.box_shadow5 {
    width: 350px;
    height: 194px;
    box-shadow: 3px 3px 35px rgb(30 168 142 / 54%);
    position: absolute;
    border-radius: 20px;
    bottom: 0;
    right: 0;
}

.fl_left_side h2 {
    /* font-family: 'Inter', sans-serif; */
    font-size: 30px;
    font-weight: 700;
    line-height: 36.31px;
    text-align: left;
    color: #1b2633;
    padding-right: 13rem;
}

.fl_left_side p {
    /* font-family: 'Inter', sans-serif; */
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    text-align: left;
    color: #676767;
    padding-right: 3.5rem;
}

.fl_left_side button {
    width: 218px;
    height: 46px;
    gap: 0px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #1f9c87;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.09px;
    text-align: center;
    color: #1ea88e;
}

.fl_right_side {
    width: 256px;
    height: 368px;
    padding: 32px 20px;
    border-radius: 20px;
    background-image: url(/wp-content/themes/biuro/i/sales-page/7f2a743f5a0e28c6196424d882f531c0.gif);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.fl_right_side span:nth-child(1) {
    font-size: 24px;
    font-weight: 400;
    line-height: 28.13px;
    text-align: left;
    display: block;
    color: #fff;
}

.fl_right_side span:nth-child(2) {
    font-weight: 700;
    font-size: 24px;
    line-height: 28.13px;
    text-align: left;
    display: block;
    color: #fff;
}

.fl_right_side button {
    width: 218px;
    height: 46px;
    gap: 0px;
    border-radius: 8px;
    background-color: #fff;
    border: none;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.09px;
    color: #1f9c87;
    text-align: center;
}

.catagory_row {
    width: 936px;
    height: 136px;
    margin: 32px auto 56px;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    a {
        text-decoration: none;
    }
}

.ct_top {
    display: flex;
    align-items: center;
    gap: 1rem;

    svg {
        color: #676767;
    }
}

.ct_row:hover {
    cursor: pointer;
    background: #fff;

    span {
        color: #1b2633;
    }

    svg {
        color: #1ea88e;
    }

    img {
        opacity: 1 !important;
    }
}

.ct_top span,
.ct_bottom span {
    color: #676767;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
    text-align: left;
}

.ct_top .ct_left,
.ct_top .ct_middle,
.ct_top .ct_right {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    flex: 1 1 25%;
    height: 64px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    padding-left: 20px;
    position: relative;

    img {
        opacity: 0;
        position: absolute;
        right: 20px;
        top: 20px;
    }
}

.ct_top .ct_right {
    flex: 1 1 15%;
}
.ct_top .ct_middle {
    flex: 1 1 30%;
}

.ct_top .ct_right span {
    padding-left: 15px;
    border-left: 1px solid #d9d9d9;
}

.ct_top .ct_left span {
    padding-left: 15px;
    border-left: 1px solid #d9d9d9;
}

.ct_top .ct_middle span {
    padding-left: 15px;
    border-left: 1px solid #d9d9d9;
}

.ct_bottom {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.ct_bottom .ct_right,
.ct_bottom .ct_left {
    flex: 1 1 40%;
    height: 64px;
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    gap: 15px;
    border-radius: 10px;
    padding-left: 20px;
    position: relative;

    img {
        opacity: 0;
        position: absolute;
        right: 20px;
        top: 20px;
    }
}

.ct_bottom .ct_left span {
    padding-left: 15px;
    border-left: 1px solid #d9d9d9;
}

.ct_bottom .ct_right span {
    padding-left: 15px;
    border-left: 1px solid #d9d9d9;
}

.office_logo_row {
    width: 936px;
    /* height: 567.32px; */
    margin: 2rem auto;
}

.office_banner {
    width: 100%;
    margin-bottom: 32px;
}

.office_banner img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    display: block;
    border: 3px solid #000;
}

.play {
    display: none;
}

.office_logo_row h4 {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: center;
    color: #676767;
    margin: 1.5rem 0;
}

.office_logo_row .brands {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 2rem;
}

.hiring_row {
    width: 936px;
    margin: 64px auto 48px;
    display: flex;
    gap: 1rem;
}

.hidden2 {
    display: none;
}

.hr_left {
    width: 352px;
    height: 298px;
    border-radius: 20px;
    background: #1b2633;
    color: #f7f7f7;
    padding: 45px 40px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: space-around;
}

.hr_left h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 28.13px;
    text-align: left;
    color: #f7f7f7;
}

.hr_left p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.hr_right {
    width: 568px;
    /* height: 304px; */
    top: 1555px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.hr_rt_top {
    width: 100%;
    height: 128px;
    background: #ffffff;
    border-radius: 20px;
    padding: 43px 40px;
    /* padding: 23px 20px; */
    gap: 25px;
    align-items: center;
    display: flex;
    justify-content: flex-start;
}
.en_bottom .star {
    flex: 0 0 24px;
    margin-left: 20px;
}

.hr_rt_top .icon {
    display: inline-block;
    float: left;
    flex: 0 0 40px;
}
.hr_rt_top hr {
    width: 1px;
    height: 87px;
    background: #d9d9d9;
    margin: 0 15px;
    float: left;
    border: 0;
}

/* hr {
    color: #d9d9d9;
    height: 87px;
    width: 1px;
} */

.hr_rt_top p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #676767;
    display: inline;
    margin: 0;
}

.hr_rt_top .bold {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
}

.hr_rt_bottom {
    width: 100%;
    display: flex;
    gap: 15px;
}

@media (max-width: 768px) {
    .hr_rt_bottom {
        flex-direction: column;
    }

    .hr_rt_bottom .bottom_left {
        width: 100%;
    }
}

.hr_rt_bottom .bottom_left {
    width: 328px;
    height: 160px;
    border-radius: 20px;
    background: #ffffff;
    padding: 2rem 35px;
    display: flex;
    align-items: center;

    p {
        margin: 0;
    }
}

.bottom_left p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #676767;
    margin: 0;
}

.bottom_left .bold {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
}

.shadow_container6 {
    width: 224px;
    height: 160px;
    position: relative;
}

.bottom_right {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: #f7f7f7;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    z-index: 2;
}

.box_shadow6 {
    width: 205px;
    height: 98px;
    box-shadow: 3px 3px 35px rgb(30 168 142 / 54%);
    position: absolute;
    border-radius: 20px;
    bottom: 5px;
    right: 5px;
    position: absolute;
}

.bottom_right h2 {
    font-size: 44px;
    font-weight: 700;
    line-height: 51.56px;
    text-align: left;
    color: #1b2633;
}

@media (min-width: 769px) and (max-width: 950px) {
    .bottom_right h2 {
        margin-bottom: 4px;
    }
}

.bottom_right p {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    color: #676767;
}

.bottom_right .bold {
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
    text-align: center;
}

.entry_lavel_row {
    width: 936px;
    /* height: 304px; */
    margin: 64px auto 0;
    display: flex;
    gap: 1rem;
    flex-direction: column;
}

.en_top {
    width: 936px;
    height: 144px;
    border-radius: 20px;
    color: #f7f7f7;
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to left, #12b18c 0.36%, #1b2633 75.66%);
}

.hidden3 {
    display: none;
}

.en_top .line_img {
    margin-top: 6px;
}

.en_top p {
    font-size: 24px;
    font-weight: 700;
    line-height: 28.13px;
    text-align: left;
    margin: 0;
    padding: 0 20px;
}

.en_bottom {
    width: 936px;
    height: 144px;
    display: flex;
    gap: 15px;
}

.en_bottom .en_b_left {
    width: 468px;
    height: 144px;
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 2.5rem;
}

.en_bottom .en_b_left p {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #676767;
    padding-left: 24px;
    margin-bottom: 0;
}

.en_bottom .en_b_left .bold {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #1b2633;
}

.shadow_container {
    width: 468px;
    height: 144px;
    position: relative;
}

.en_bottom .en_b_right {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f7f7f7;
    z-index: 2;
}

.box_shadow {
    width: 301px;
    height: 96px;
    box-shadow: 5px 5px 35px rgb(30 168 142 / 54%);
    position: absolute;
    right: 5px;
    bottom: 5px;
    border-radius: 20px;
}

.en_bottom .en_b_right p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #676767;
    padding: 3rem;
}

.relevent_skills_row {
    width: 936px;
    height: 458px;
    margin: 10rem auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
}

.top_arrow {
    position: absolute;
    top: -35px;
    left: -30px;
}

.bottom_arrow {
    position: absolute;
    bottom: -35px;
    right: -30px;
}

.rlv_top {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    z-index: 1;
}

.rlv_top .rlv_left {
    width: 248px;
    height: 170px;
    border-radius: 20px;
    background: #1b2633;
    color: #f7f7f7;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.rlv_top .rlv_left h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 28.13px;
    text-align: left;
    color: #f7f7f7;
}

.rlv_top .rlv_middle {
    width: 376px;
    height: 170px;
    border-radius: 20px;
    background: #dcdcdc;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 25px;
}

.rlv_top .rlv_middle p {
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #f7f7f7;
}

.underline {
    background-color: #f7f7f7;
    width: 312px;
    height: 1px;
    border: none;
}

.underline2 {
    background-color: #f7f7f7;
    width: 221px;
    height: 1px;
    border: none;
}

.rlv_top .rlv_right {
    width: 280px;
    height: 170px;
    border-radius: 20px;
    background: #dcdcdc;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 25px;
}

.rlv_top .rlv_right p {
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #f7f7f7;
}

.rlv_bottom {
    display: flex;
    z-index: 1;
}

.rlv_bottom .rlv_middle {
    width: 530px;
    display: flex;
    gap: 15px;
    flex-direction: column;
}

.inner_container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.inner_container .total_left {
    display: flex;
    gap: 15px;
}

.rlv_middle .middle_right {
    width: 336px;
    height: 123px;
    border-radius: 20px;
    background: #dcdcdc;
    padding: 20px 25px;
    margin-left: 11rem;
}

.underline4 {
    background-color: #f7f7f7;
    width: 285px;
    height: 1px;
    border: none;
}

.rlv_middle .middle_right p {
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #f7f7f7;
}

.inner_container .total_left .middle_left {
    width: 248px;
    height: 123px;
    border-radius: 20px;
    background: #dcdcdc;
    padding: 28px 20px 25px;
}

.underline3 {
    background-color: #f7f7f7;
    width: 203px;
    height: 1px;
    border: none;
}

.inner_container .total_left .middle_left p {
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #f7f7f7;
}

.inner_container .total_left .middle_middle {
    width: 248px;
    height: 128px;
    border-radius: 20px;
    background-image: url(/wp-content/themes/biuro/i/sales-page/7f2a743f5a0e28c6196424d882f531c0.gif);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 25px;
}

.inner_container .total_left .middle_middle p {
    font-size: 20px;
    font-weight: 700;
    line-height: 23.44px;
    text-align: left;
    color: #f7f7f7;
}

.shadow_container4 {
    width: 409px;
    height: 272px;
    position: relative;
}

.rlv_bottom .rlv_right {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background: #f7f7f7;
    padding: 9% 35px;
    position: absolute;
    z-index: 2;
}

.box_shadow4 {
    width: 100%;
    height: 250px;
    box-shadow: 3px 3px 35px rgb(30 168 142 / 54%);
    position: absolute;
    border-radius: 20px;
    bottom: 0;
    right: 0;
}

.underline5 {
    background-color: #d9d9d9;
    width: 340px;
    height: 1px;
    border: none;
}

.rlv_bottom .rlv_right p {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: #676767;
    margin-bottom: 0;
}

.rlv_bottom .rlv_right span {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #676767;
}

.rlv_bottom .rlv_right .bold {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
}

.job_opening_row {
    width: 936px;
    height: 465.99px;
    margin: 10rem auto 6rem;
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
}

.job_opening_row .jb_ss_bottom {
    display: none;
}

.jb_top {
    display: flex;
    justify-content: space-between;
}

.jb_top .jb_left {
    width: 352px;
    height: 259px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    background: #1b2633;
    color: #fff;
    padding: 25px;
}

.jb_top .jb_left h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 28.13px;
    text-align: left;
    color: #fff;
}

.jb_top .jb_left p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.underline6 {
    background: #676767;
    width: 300px;
    height: 1px;
    border: none;
    margin-top: -20px;
}

.jb_top .jb_right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 568px;
    height: 259px;
    border-radius: 20px;
    background: #ffffff;
    padding: 25px;
}

.jb_right .inner_left {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 0.8rem;
}

.jb_right .inner_left h2 {
    font-size: 44px;
    font-weight: 700;
    line-height: 51.56px;
    text-align: left;
    color: #1b2633;
}

.jb_right .inner_left .bold {
    font-size: 48px;
    font-weight: 700;
    line-height: 56.25px;
    text-align: left;
    color: #1b2633;
}

.jb_right .inner_left p {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    color: #1b2633;
    position: relative;
    top: -12px;
}

.jb_bottom {
    width: 100%;
    height: 191px;
    display: flex;
    /* justify-content: space-between; */
}

.left_bg {
    background-image: url(/wp-content/themes/biuro/i/sales-page/rectangle10405.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 282px;
    height: 191px;
    display: flex;
    flex-direction: column;
    padding: 25px 35px;
    align-items: flex-start;
    justify-content: space-around;
}

.middle_bg {
    background-image: url(/wp-content/themes/biuro/i/sales-page/rectangle10476.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 355px;
    height: 191px;
    top: 3495.99px;
    display: flex;
    flex-direction: column;
    padding: 25px 63px;
    align-items: flex-start;
    justify-content: space-around;
}

.right_bg {
    background-image: url(/wp-content/themes/biuro/i/sales-page/7f2a743f5a0e28c6196424d882f531c0.gif);
    background-repeat: no-repeat;
    background-position: center;
    background-size: content;

    -webkit-mask-image: url(/wp-content/themes/biuro/i/sales-page/maskgroup.png);
    mask-image: url(/wp-content/themes/biuro/i/sales-page/maskgroup.png);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    width: 386px;
    height: 191px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 25px 69px;
    align-items: flex-start;
    justify-content: space-around;
}

.jb_bottom .jb_left h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 28.13px;
    text-align: left;
    color: #ffffff;
}

.jb_bottom .jb_left .bold {
    font-size: 24px;
    font-weight: 400;
    line-height: 28.13px;
    text-align: left;
    color: #ffffff;
}

.jb_bottom .left_bg h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 28.13px;
    text-align: left;
    color: #1b2633;
}

.jb_bottom .left_bg .bold {
    font-size: 24px;
    font-weight: 400;
    line-height: 28.13px;
    text-align: left;
    color: #1b2633;
}

.about_row {
    width: 936px;
    height: 440px;
    display: flex;
    flex-direction: column;
    margin: 10rem auto;
}

.ab_top_container {
    display: flex;
    gap: 15px;
    justify-content: space-between;
}

.ab_left {
    width: 440px;
    height: 288px;
    border-radius: 20px;
    background: #1b2633;
    color: #f7f7f7;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}

.ss_work {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.underline8 {
    background: #676767;
    width: 383px;
    height: 1px;
    border: none;
    margin-top: -12px;
}

.ab_left h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 28.13px;
    text-align: left;
    color: #f7f7f7;
}

.ab_left p {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    color: #f7f7f7;
}

.ab_left .bold {
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
    text-align: left;
    color: #f7f7f7;
}

.ab_middle {
    width: 200px;
    height: 288px;
    border-radius: 20px;
    background-image: url(/wp-content/themes/biuro/i/sales-page/7f2a743f5a0e28c6196424d882f531c0.gif);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #f7f7f7;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}
.star_img {
    display: flex;
    align-self: center;
}

.underline9 {
    background: #ffffff;
    width: 147px;
    height: 1px;
    border: none;
    position: relative;
    top: 15px;
}

.ab_middle .text {
    display: flex;
    gap: 5px;
}

.ab_middle .text span:nth-child(1) {
    font-size: 44px;
    font-weight: 700;
    line-height: 51.56px;
    text-align: left;
    color: #fff;
    position: relative;
    top: 10px;
}

.ab_middle .text span:nth-child(2) {
    font-size: 18px;
    font-weight: 700;
    line-height: 21.09px;
    text-align: left;
    color: #fff;
    position: relative;
    top: 15px;
}

.ab_middle p {
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
    text-align: left;
    color: #fff;
}

.ab_right {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.ab_right .ab_top,
.ab_right .ab_bottom {
    width: 264px;
    height: 136px;
    border-radius: 20px;
    background: #fff;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    gap: 10px;
}

.ab_right .ab_top h2,
.ab_right .ab_bottom h2 {
    font-size: 44px;
    font-weight: 700;
    line-height: 51.56px;
    text-align: left;
    color: #1b2633;
    margin: 0px;
}

.ab_right .ab_top p,
.ab_right .ab_bottom p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
    text-align: left;
    color: #1b2633;
}

.ab_right .ab_top .bold,
.ab_right .ab_bottom .bold {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    color: #676767;
}

.ab_bottom_container {
    display: flex;
    gap: 15px;
    margin-top: 15px;
}

.ab_bottom_container .ab_bt_left,
.ab_bottom_container .ab_bt_right {
    width: 320px;
    height: 136px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    gap: 10px;
    padding: 25px;
    background: #ffffff;
}

.ab_bottom_container .ab_bt_left h2,
.ab_bottom_container .ab_bt_right h2 {
    font-size: 44px;
    font-weight: 700;
    line-height: 51.56px;
    text-align: left;
    color: #1b2633;
    margin: 0px;
}

.ab_bottom_container .ab_bt_left p,
.ab_bottom_container .ab_bt_right p {
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
    text-align: left;
    color: #1b2633;
}

.ab_bottom_container .ab_bt_left .bold,
.ab_bottom_container .ab_bt_right .bold {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    color: #676767;
}

.get_touch_row {
    width: 936px;
    height: 114px;
    border-radius: 20px;
    background-image: url(/wp-content/themes/biuro/i/sales-page/7f2a743f5a0e28c6196424d882f531c0.gif);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10rem auto;
    padding: 25px;
}

.get_touch_row button {
    width: 218px;
    height: 46px;
    gap: 0px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #1f9c87;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.09px;
    text-align: center;
    color: #1ea88e;
}

.get_touch_row p {
    font-size: 22px;
    font-weight: 400;
    line-height: 25.78px;
    text-align: left;
    color: #fff;
    margin-bottom: 0;
}

.get_touch_row .bold {
    font-size: 22px;
    font-weight: 700;
    line-height: 25.78px;
    text-align: left;
    color: #fff;
}

.client_feedback_row {
    width: 936px;
    height: 273px;
    margin: 7rem auto 6rem;
    display: flex;
    gap: 15px;
}

.slider_container {
    width: 650px;
    height: 272px;
    overflow: scroll;
}

.clients {
    display: flex;
    gap: 15px;
    width: 100%;
    height: 100%;
}

.client {
    width: 280px !important;
    height: 272px !important;
    display: flex !important;
}

.client_feedback_row .one {
    width: 280px;
    height: 272px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: 25px;
    background: #1b2633;
}

.client_feedback_row .one h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 28.13px;
    text-align: left;
    color: #f7f7f7;
}

.client_feedback_row .one p {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    color: #939393;
}

.client_feedback_row .one .bold {
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
    text-align: left;
    color: #ffffff;
}

.client {
    width: 280px;
    height: 272px;
    border-radius: 20px;
    background: #ffffff;
    padding: 25px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
}

.c_img {
    width: 59.16px;
    height: 59.16px;
    border-radius: 50%;
}

.client p {
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
    text-align: left;
}

.client .details {
    display: flex;
    flex-direction: column;
}

.client .details span {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    color: #676767;
}

.hr_effort_row {
    width: 949px;
    height: 256px;
    margin: 6rem auto;
    display: flex;
    gap: 15px;
}

.shadow_container2 {
    width: 677px;
    height: 256px;
    position: relative;
}

.hr_effort_row .hr_left {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: #f7f7f7;
    color: #1b2633;
    position: absolute;
    z-index: 2;
}

.box_shadow3 {
    width: 350px;
    height: 194px;
    box-shadow: 3px 3px 35px rgb(30 168 142 / 54%);
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 20px;
}

.hr_effort_row .hr_left h2 {
    font-size: 38px;
    font-weight: 500;
    line-height: 44.53px;
    text-align: left;
}

.hr_effort_row .hr_left .bold {
    font-size: 38px;
    font-weight: 700;
    line-height: 44.53px;
    text-align: left;
}

.hr_effort_row .hr_left p {
    font-size: 24px;
    font-weight: 400;
    line-height: 28.13px;
    text-align: left;
    color: #676767;
}

.hr_effort_row .fl_right_side {
    width: 256px;
    height: 256px;
    border-radius: 20px;
}

/* Start responsive code  */
/* min width 1024 and max width 1400 css media query */
@media (min-width: 1900px) {
    .scrool_bar {
        left: 22%;
    }
}
@media (min-width: 1600px) and (max-width: 1899px) {
    .scrool_bar {
        left: 18%;
    }
}
@media (max-width: 1400px) {
    .scrool_bar {
        left: 12%;
    }
}
@media (max-width: 1300px) {
    .scrool_bar {
        left: 8%;
    }
}
@media (max-width: 1200px) {
    .scrool_bar {
        left: 3%;
    }
}
@media (max-width: 1100px) {
    .scrool_bar {
        left: 2%;
    }
}
@media (max-width: 1023px) {
    .scrool_bar {
        display: none;
    }
}
@media (max-width: 950px) {
    .swiper-pagination-vertical.swiper-pagination-bullets {
        display: none;
    }

    .flexible_row {
        width: 100%;
        height: 440px;
    }

    .shadow_container5 {
        width: 664px;
        height: auto;
    }

    .fl_left_side h2 {
        padding-right: 0rem;
    }

    .fl_left_side p {
        /* font-family: 'Inter', sans-serif; */
        font-size: 16px;
        font-weight: 400;
        line-height: 19.36px;
        text-align: left;
        color: #676767;
        padding-right: 0rem;
    }

    .shadow_container5 {
        height: auto;
    }

    .fl_right_side {
        height: auto;
        margin-right: 15px;
    }

    .fl_left_side {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 2rem;
        position: absolute;
        z-index: 2;
        background: #fff;
        gap: 0rem;
    }

    .catagory_row {
        display: none;
    }

    .office_logo_row {
        width: 93%;
        /* height: 567.32px; */
        margin: 2rem auto;
    }

    .hiring_row {
        width: 80%;
        flex-direction: column;
    }

    .hr_left {
        width: 100%;
        /* height: 304px; */
        border-radius: 20px;
        background: #1b2633;
        color: #f7f7f7;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        gap: 15px;
        justify-content: space-around;
    }

    .hr_right {
        width: 100%;
        /* height: 304px; */
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .hidden2 {
        display: block;
        width: 100%;
        /* height: 163px; */
        border-radius: 20px;
        background: #ffffff;
        padding: 25px;
    }

    .hr_rt_top {
        width: 100%;
        height: 131px;
    }

    .show {
        display: none;
    }

    .shadow_container6 {
        width: 100%;
        height: 160px;
        border-radius: 20px;
    }

    .box_shadow6 {
        box-shadow: -10px 10px 35px rgb(30 168 142 / 54%);
        width: 88%;
        right: 30px;
    }

    .bottom_right {
        justify-content: center;
        align-items: center;
        gap: 5px;
    }

    .bottom_right p {
        /* padding: 0 78px; */
        padding: 0 18px;
        text-align: center;
    }

    .entry_lavel_row {
        width: 80%;
        margin: 0 auto;
    }

    .en_top {
        width: 100%;
    }

    .hidden3 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        position: relative;
    }

    .arrow_one {
        display: flex;
        /* align-items: flex-end; */
        gap: 1rem;
        position: absolute;
        right: 0;
        bottom: 75px;
        align-items: center;
    }

    .arrow_two {
        position: absolute;
        left: 0;
        bottom: 30px;
    }

    .en_bottom {
        display: block;
        width: 100%;
    }

    .en_bottom .en_b_left {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 124px;
        border-radius: 20px;
        gap: 25px;
    }

    .en_bottom .en_b_left p {
        padding-right: 15px;
    }

    .shadow_container {
        width: 100%;
    }

    .box_shadow {
        width: 90%;
    }

    .relevent_skills_row {
        width: 80%;
        height: 608px;
    }

    .rlv_top {
        width: 100%;
        height: 170px;
        border-radius: 20px;
        margin-top: 50px;
    }

    .rlv_top .rlv_left {
        width: 100%;
        height: 170px;
        border-radius: 20px;
    }

    .rlv_bottom .rlv_middle {
        width: 100%;
    }

    .rlv_top .rlv_left h2 {
        padding-right: 3rem;
    }

    .inner_container .total_left .middle_middle {
        width: 100%;
        height: 121px;
        border-radius: 20px;
    }

    .inner_container .total_left .middle_middle p {
        padding-right: 0rem;
        margin-bottom: 0;
    }

    .shadow_container4 {
        width: 100%;
        height: 285px;
    }

    .box_shadow4 {
        width: calc(100% - 35px);
        right: 35px;
    }

    .underline5 {
        width: 100%;
    }

    .underline6 {
        width: 100%;
    }

    .rlv_bottom {
        flex-direction: column;
    }

    .rlv_top .rlv_middle,
    .rlv_top .rlv_right {
        display: none;
    }

    .top_arrow,
    .bottom_arrow {
        display: none;
    }

    .rlv_bottom .rlv_middle .middle_left {
        display: none;
    }

    .rlv_bottom .rlv_middle .middle_right {
        display: none;
    }

    .job_opening_row {
        width: 80%;
        height: 1140.99px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .jb_top {
        flex-direction: column;
    }

    .jb_bottom {
        flex-direction: column;
    }

    .jb_top .jb_left {
        width: 100%;
    }

    .jb_top .jb_right {
        width: 100%;
        border-radius: 20px;
        margin-top: 1rem;
    }

    .jb_right .inner_left .icon {
        display: none;
    }

    .jb_right .inner_right img {
        width: 110px;
        height: 110;
    }

    .job_opening_row .jb_bottom {
        display: none;
    }

    .jb_ss_bottom {
        width: 360px;
        height: 597.49px;
        border-radius: 20px;
    }

    .jb_ss_bottom .jb_ss_top {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: flex-start;
        width: 360px;
        height: 211px;
        background-image: url(/wp-content/themes/biuro/i/sales-page/new.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        padding: 50px 25px 75px 25px;
        gap: 2rem;
    }

    .jb_ss_bottom .jb_ss_top h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 28.13px;
        text-align: left;
        color: #1b2633;
    }

    .jb_ss_bottom .jb_ss_top .bold {
        font-size: 24px;
        font-weight: 400;
        line-height: 28.13px;
        text-align: left;
        color: #1b2633;
    }

    .jb_ss_bottom .jb_ss_middle {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: flex-start;
        width: 360px;
        height: 238px;
        background-image: url(/wp-content/themes/biuro/i/sales-page/new2.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        padding: 50px 25px 75px 25px;
        gap: 2rem;
    }

    .jb_ss_bottom .jb_ss_middle h3 {
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        line-height: 28.13px;
        text-align: left;
    }

    .jb_ss_bottom .jb_ss_middle .bold {
        font-size: 24px;
        font-weight: 400;
        line-height: 28.13px;
        text-align: left;
        color: #fff;
    }

    .job_opening_row .jb_ss_bottom {
        display: block;
        /* margin-left: 3rem; */
    }

    .jb_ss_bottom .jb_ss_inbottom {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: flex-start;
        background-image: url(/wp-content/themes/biuro/i/sales-page/hello.png);
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: contain;
        padding: 45px 25px;
        gap: 2rem;
    }

    .jb_ss_bottom .jb_ss_inbottom h3 {
        font-size: 24px;
        line-height: 28.13px;
        text-align: left;
        color: #fff;
    }

    .about_row {
        width: 80%;
        height: 917px;
        flex-direction: column;
        margin-top: 10rem;
    }

    .ab_top_container {
        flex-direction: column;
    }

    .ab_left {
        width: 100%;
        height: 294px;
        border-radius: 20px;
    }

    .underline8 {
        width: 100%;
    }

    .ab_middle {
        flex-direction: row;
        width: 100%;
        height: 151px;
        border-radius: 20px;

        padding: 16px 45px 0px 0px;
        justify-content: space-between;
        align-items: flex-start;
    }

    .star_img {
        margin: -16px 0 0 10%;
    }

    .underline9 {
        width: 1px;
        height: 100px;
        background: #ffffff;
    }

    .ss_work {
        padding-bottom: 1rem;
    }

    .ab_right {
        flex-direction: row;
    }
    .ab_right .ab_top,
    .ab_right .ab_bottom {
        width: 50%;
    }

    .ab_bottom_container {
        display: flex;
        flex-direction: column;
    }

    .ab_bottom_container .ab_bt_left {
        width: 100%;
        height: 136px;
        border-radius: 20px;
    }

    .ab_bottom_container .ab_bt_right {
        width: 100%;
        height: 136px;
        border-radius: 20px;
    }

    .get_touch_row {
        width: 80%;
        height: 226px;
        flex-direction: column;
        justify-content: space-around;
        margin: -4rem auto;
    }

    .get_touch_row p {
        padding-right: 1rem;
    }

    .get_touch_row button {
        width: 100%;
        height: 46px;
    }
    .get_touch_row a {
        width: 100%;
    }

    .slider_container {
        padding: 0 5px;
        width: 100%;
    }

    .client_feedback_row {
        width: 80%;
        height: 449px;
        flex-direction: column;
    }

    .client_feedback_row .one {
        width: 100%;
        height: 250px;
    }

    .client_feedback_row .clients {
        flex-direction: row;
    }

    .client_feedback_row .clients .client {
        width: 300px;
        height: 273px;
    }

    .hr_effort_row {
        width: 80%;
        height: 418px;
        flex-direction: column;
    }

    .shadow_container2 {
        width: 100%;
        height: 184px;
    }

    .shadow_container2 .hr_left h2 {
        /* font-family: Inter; */
        font-size: 26px;
        font-weight: 500;
        line-height: 31.47px;
        text-align: left;
    }

    .shadow_container2 .hr_left .bold {
        /* font-family: Inter; */
        font-size: 26px;
        font-weight: 700;
        line-height: 31.47px;
        text-align: left;
    }

    .shadow_container2 .hr_left p {
        font-size: 16px;
        font-weight: 400;
        line-height: 18.75px;
        text-align: left;
    }

    .box_shadow3 {
        width: 100%;
        max-width: 430px;
        height: 140px;
        box-shadow: 3px 3px 35px rgb(30 168 142 / 54%);
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .hr_effort_row .fl_right_side {
        width: 100%;
        height: 226px;
    }

    .fl_right_side button {
        width: 100%;
    }
}

@media (min-width: 769px) and (max-width: 950px) {
    .hr_rt_bottom .bottom_left {
        flex: 2 1 5%;
    }
    .shadow_container6 {
        flex: 1 1 15%;
    }
}

@media (max-width: 768px) {
    .swiper-pagination-vertical.swiper-pagination-bullets {
        display: none;
    }

    .fl_slider_warp .fl_slider:nth-child(2),
    .fl_slider_warp .fl_slider:nth-child(3) {
        display: none;
    }

    .shadow_container5 {
        width: 100% !important;
        height: 60% !important;
        position: relative;
    }

    .flexible_row {
        display: flex;
        width: 93% !important;
        height: 693px !important;
        flex-direction: column;
    }

    .fl_left_side button {
        width: 100%;
    }

    .fl_slider {
        flex-direction: column;
    }

    .fl_left_side h2 {
        padding-right: 0rem;
    }

    .fl_left_side p {
        padding-right: 0rem;
    }

    .fl_right_side {
        width: 100% !important;
        height: 35% !important;
    }

    .catagory_row {
        display: none;
    }

    .office_logo_row {
        width: 93%;
    }

    .office_banner {
        width: 100%;
        padding-top: 57%;
        background: url(/wp-content/themes/biuro/i/sales-page/office_banner.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin-bottom: 24px;
        border-radius: 17px;
        display: block;
        border: 3px solid #000;
    }

    .office_banner .hide {
        display: none;
    }

    .play {
        display: flex;
        width: 87.5%;
        height: 46px;
        border-radius: 8px;
        gap: 1rem;
        background: #fff;
        position: relative;
        bottom: 65px;
        left: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .play span {
        font-size: 18px;
        font-weight: 700;
        line-height: 21.09px;
        text-align: left;
    }

    .office_logo_row .brands {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: center;
    }

    .hiring_row {
        width: 93%;
        flex-direction: column;
    }

    .hidden2 {
        width: 100%;
    }

    .hr_left {
        width: 100%;
    }

    .hr_right {
        width: 100%;
    }

    .hr_rt_top {
        width: 100%;
    }

    .show {
        display: none;
    }

    .shadow_container6 {
        width: 100%;
    }

    .box_shadow6 {
        width: 85%;
    }

    .bottom_right {
        justify-content: center;
        align-items: center;
        gap: 15px;
    }

    .bottom_right p {
        padding: 0 78px;
        text-align: center;
    }

    .entry_lavel_row {
        width: 93%;
        margin: 32px auto 0;
    }

    .en_top {
        width: 100%;
    }

    .hidden3 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        position: relative;
    }

    .arrow_one {
        display: flex;
        /* align-items: flex-end; */
        gap: 1rem;
        position: absolute;
        right: 0;
        bottom: 75px;
        align-items: center;
    }

    .arrow_two {
        position: absolute;
        left: 0;
        bottom: 30px;
    }

    .en_bottom {
        display: block;
        width: 100%;
    }

    .en_bottom .en_b_left {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 124px;
        border-radius: 20px;
        gap: 25px;
    }

    .en_bottom .en_b_left p {
        padding-right: 15px;
    }

    .shadow_container {
        width: 100%;
    }

    .box_shadow {
        width: 90%;
    }

    .relevent_skills_row {
        width: 93%;
        height: 608px;
    }

    .rlv_top {
        width: 100%;
        height: 170px;
        border-radius: 20px;
        margin-top: 50px;
    }

    .rlv_top .rlv_left {
        width: 100%;
        height: 170px;
        border-radius: 20px;
    }

    .rlv_bottom .rlv_middle {
        width: 100%;
    }

    .rlv_top .rlv_left h2 {
        padding-right: 3rem;
    }

    .inner_container .total_left .middle_middle {
        width: 100%;
        height: 121px;
        border-radius: 20px;
    }

    .inner_container .total_left .middle_middle p {
        padding-right: 0rem;
    }

    .shadow_container4 {
        width: 100%;
        height: 285px;
    }

    .box_shadow4 {
        width: calc(100% - 35px);
        right: 35px;
    }

    .underline5 {
        width: 100%;
    }

    .rlv_bottom {
        flex-direction: column;
    }

    .rlv_top .rlv_middle,
    .rlv_top .rlv_right {
        display: none;
    }

    .top_arrow,
    .bottom_arrow {
        display: none;
    }

    .rlv_bottom .rlv_middle .middle_left {
        display: none;
    }

    .rlv_bottom .rlv_middle .middle_right {
        display: none;
    }

    .job_opening_row {
        width: 93%;
        height: 1140.99px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .jb_top {
        flex-direction: column;
    }

    .jb_bottom {
        flex-direction: column;
    }

    .jb_top .jb_left {
        width: 100%;
    }

    .jb_top .jb_right {
        width: 100%;
        border-radius: 20px;
        margin-top: 1rem;
    }

    .jb_right .inner_left .icon {
        display: none;
    }

    .jb_right .inner_right img {
        width: 110px;
        height: 110;
    }

    .job_opening_row .jb_bottom {
        display: none;
    }

    .jb_ss_bottom {
        width: 360px;
        height: 597.49px;
        border-radius: 20px;
    }

    .jb_ss_bottom .jb_ss_top {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: flex-start;
        width: 360px;
        height: 211px;
        background-image: url(/wp-content/themes/biuro/i/sales-page/new.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        padding: 50px 25px 75px 25px;
        gap: 2rem;
    }

    .jb_ss_bottom .jb_ss_top h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 28.13px;
        text-align: center;
        color: #1b2633;
    }

    .jb_ss_bottom .jb_ss_top .bold {
        font-size: 24px;
        font-weight: 400;
        line-height: 28.13px;
        text-align: left;
        color: #1b2633;
    }

    .jb_ss_bottom .jb_ss_middle {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: flex-start;
        width: 360px;
        height: 238px;
        background-image: url(/wp-content/themes/biuro/i/sales-page/new2.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        padding: 50px 25px 75px 25px;
        gap: 2rem;
    }

    .jb_ss_bottom .jb_ss_middle h3 {
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        line-height: 28.13px;
        text-align: center;
    }

    .jb_ss_bottom .jb_ss_middle .bold {
        font-size: 24px;
        font-weight: 400;
        line-height: 28.13px;
        text-align: left;
        color: #fff;
    }

    .job_opening_row .jb_ss_bottom {
        display: block;
        /* margin-left: 3rem; */
    }

    .jb_ss_bottom .jb_ss_inbottom {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        background-image: url(/wp-content/themes/biuro/i/sales-page/hello.png);
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: contain;
        padding: 45px 25px;
        gap: 2rem;
    }

    .about_row {
        width: 93%;
        height: 917px;
        flex-direction: column;
        margin-top: 10rem;
    }

    .ab_top_container {
        flex-direction: column;
    }

    .ab_left {
        width: 100%;
        height: 294px;
        border-radius: 20px;
    }

    .underline8 {
        width: 100%;
    }

    .ab_middle {
        flex-direction: row;
        width: 100%;
        height: 151px;
        border-radius: 20px;

        padding: 16px 45px 0px 0px;
        justify-content: space-between;
        align-items: flex-start;
    }

    .underline9 {
        width: 1px;
        height: 100px;
        background: #ffffff;
    }

    .ss_work {
        padding-bottom: 1rem;
    }

    .ab_right {
        flex-direction: row;
    }

    .ab_bottom_container {
        display: flex;
        flex-direction: column;
    }

    .ab_bottom_container .ab_bt_left {
        width: 100%;
        height: 136px;
        border-radius: 20px;
    }

    .ab_bottom_container .ab_bt_right {
        width: 100%;
        height: 136px;
        border-radius: 20px;
    }

    .get_touch_row {
        width: 93%;
        height: 226px;
        flex-direction: column;
        justify-content: space-around;
        margin: -4rem auto;
    }

    .get_touch_row p {
        padding-right: 1rem;
    }

    .get_touch_row button {
        width: 100%;
        height: 46px;
    }

    .slider_container {
        padding: 0 5px;
    }

    .client_feedback_row {
        width: 93%;
        height: 549px;
        flex-direction: column;
    }

    .client_feedback_row .one {
        width: 100%;
        height: 250px;
    }

    .client_feedback_row .clients {
        flex-direction: row;
    }

    .client_feedback_row .clients .client {
        width: 300px;
        height: 273px;
    }

    .hr_effort_row {
        width: 93%;
        height: 418px;
        flex-direction: column;
    }

    .shadow_container2 {
        width: 100%;
        height: 184px;
    }

    .shadow_container2 .hr_left h2 {
        /* font-family: Inter; */
        font-size: 26px;
        font-weight: 500;
        line-height: 31.47px;
        text-align: left;
    }

    .shadow_container2 .hr_left .bold {
        /* font-family: Inter; */
        font-size: 26px;
        font-weight: 700;
        line-height: 31.47px;
        text-align: left;
    }

    .shadow_container2 .hr_left p {
        font-size: 16px;
        font-weight: 400;
        line-height: 18.75px;
        text-align: left;
    }

    .box_shadow3 {
        width: 100%;
        max-width: 430px;
        height: 140px;
        box-shadow: 3px 3px 35px rgb(30 168 142 / 54%);
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .hr_effort_row .fl_right_side {
        width: 100% !important;
        height: 226px !important;
    }

    .fl_right_side button {
        width: 100%;
    }
}
@media (max-width: 375px) {
    .scrool_bar {
        display: none;
    }
    .flexible_row {
        display: flex;
        width: 361px;
        height: 693px;
        flex-direction: column;
    }

    .fl_slider_warp .fl_slider:nth-child(2),
    .fl_slider_warp .fl_slider:nth-child(3) {
        display: none;
    }

    .shadow_container5 {
        width: 361px;
        height: 451px;
        border-radius: 20px;
    }

    .box_shadow5 {
        width: 268px;
        height: 194px;
    }

    .fl_left_side {
        width: 361px;
        padding: 25px;
        justify-content: space-around;
    }

    .fl_slider {
        display: flex;
        flex-direction: column;
    }

    .fl_left_side h2 {
        padding-right: 0;
    }

    .fl_left_side p {
        padding-right: 0;
    }

    .fl_left_side button {
        width: 311px;
        border-radius: 8px;
    }

    .fl_right_side button {
        width: 311px;
        border-radius: 8px;
    }

    .fl_right_side {
        width: 361px;
        height: 226px;
    }

    .catagory_row {
        display: none;
    }

    .office_logo_row {
        width: 361px;
        height: 396.28px;
    }

    .office_banner {
        width: 100%;
        padding-top: 57%;
        background: url(/wp-content/themes/biuro/i/sales-page/office_banner.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin-bottom: 24px;
        border-radius: 17px;
        display: block;
        border: 3px solid #000;
    }

    .office_banner .hide {
        display: none;
    }

    .play {
        display: flex;
        width: 311px;
        height: 46px;
        border-radius: 8px;
        gap: 1rem;
        background: #fff;
        position: relative;
        bottom: 65px;
        left: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .play span {
        font-size: 18px;
        font-weight: 700;
        line-height: 21.09px;
        text-align: left;
    }

    .office_logo_row .brands {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: center;
    }

    .hiring_row {
        width: 358px;
        flex-direction: column;
    }

    .hidden2 {
        display: block;
        width: 358px;
        height: 163px;
        border-radius: 20px;
        background: #ffffff;
        padding: 25px;
    }

    .hr_rt_top {
        width: 358px;
        height: 131px;
    }

    .show {
        display: none;
    }

    .shadow_container6 {
        width: 358px;
        height: 160px;
        border-radius: 20px;
    }

    .box_shadow6 {
        box-shadow: -10px 10px 35px rgb(30 168 142 / 54%);
        width: 280px;
        right: 30px;
    }

    .bottom_right {
        justify-content: center;
        align-items: center;
        gap: 15px;
    }

    .bottom_right p {
        padding: 0 78px;
        text-align: center;
    }

    .entry_lavel_row {
        width: 358px;
        height: 447px;
        margin: 6rem auto;
    }

    .en_top {
        width: 358px;
        height: 167px;
        border-radius: 20px;
    }

    .hidden3 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
    }

    .arrow_one {
        display: flex;
        /* align-items: flex-end; */
        gap: 1rem;
    }

    .arrow_two {
        position: relative;
        left: -71px;
    }

    .en_bottom {
        display: block;
        /* flex-direction: column; */
    }

    .en_bottom .en_b_left {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 358px;
        height: 124px;
        border-radius: 20px;
        gap: 45px;
    }

    .en_bottom .en_b_left p {
        padding-right: 15px;
    }

    .shadow_container {
        width: 358px;
        height: 124px;
        border-radius: 20px;
        margin-top: 20px;
    }

    .box_shadow {
        box-shadow: -10px 10px 35px rgb(30 168 142 / 54%);
    }

    .relevent_skills_row {
        width: 357px;
        height: 608px;
    }

    .rlv_top {
        width: 357px;
        height: 170px;
        border-radius: 20px;
    }

    .rlv_top .rlv_left {
        width: 357px;
        height: 170px;
        border-radius: 20px;
    }

    .rlv_top .rlv_left h2 {
        padding-right: 3rem;
    }

    .inner_container .total_left .middle_middle {
        width: 357px;
        height: 121px;
        border-radius: 20px;
    }

    .inner_container .total_left .middle_middle p {
        padding-right: 6rem;
    }

    .shadow_container4 {
        width: 357px;
        height: 285px;
    }

    .box_shadow4 {
        width: calc(100% - 35px);
        right: 35px;
    }

    .underline5 {
        width: 300px;
    }

    .rlv_bottom {
        flex-direction: column;
    }

    .rlv_top .rlv_middle,
    .rlv_top .rlv_right {
        display: none;
    }

    .top_arrow,
    .bottom_arrow {
        display: none;
    }

    .rlv_bottom .rlv_middle .middle_left {
        display: none;
    }

    .rlv_bottom .rlv_middle .middle_right {
        display: none;
    }

    .job_opening_row {
        width: 360px;
        height: 1075.49px;
    }

    .jb_top {
        flex-direction: column;
    }

    .jb_bottom {
        flex-direction: column;
    }

    .jb_top .jb_right {
        width: 360px;
        height: 191px;
        border-radius: 20px;
        margin-top: 1rem;
    }

    .jb_right .inner_left .icon {
        display: none;
    }

    .jb_right .inner_right img {
        width: 110px;
        height: 110;
    }

    .job_opening_row .jb_bottom {
        display: none;
    }

    .jb_ss_bottom {
        width: 360px;
        height: 597.49px;
        border-radius: 20px;
    }

    .jb_ss_bottom .jb_ss_top {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: flex-start;
        width: 360px;
        height: 211px;
        background-image: url(/wp-content/themes/biuro/i/sales-page/new.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        padding: 50px 25px 75px 25px;
        gap: 2rem;
    }

    .jb_ss_bottom .jb_ss_top h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 28.13px;
        text-align: left;
        color: #1b2633;
    }

    .jb_ss_bottom .jb_ss_top .bold {
        font-size: 24px;
        font-weight: 400;
        line-height: 28.13px;
        text-align: left;
        color: #1b2633;
    }

    .jb_ss_bottom .jb_ss_middle {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: flex-start;
        width: 360px;
        height: 238px;
        background-image: url(/wp-content/themes/biuro/i/sales-page/new2.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        padding: 50px 25px 75px 25px;
        gap: 2rem;
    }

    .jb_ss_bottom .jb_ss_middle h3 {
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        line-height: 28.13px;
        text-align: left;
    }

    .jb_ss_bottom .jb_ss_middle .bold {
        font-size: 24px;
        font-weight: 400;
        line-height: 28.13px;
        text-align: left;
        color: #fff;
    }

    .job_opening_row .jb_ss_bottom {
        display: block;
    }

    .jb_ss_bottom .jb_ss_inbottom {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: flex-start;
        background-image: url(/wp-content/themes/biuro/i/sales-page/hello.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        padding: 50px 25px 75px 25px;
        gap: 2rem;
    }

    .jb_ss_bottom .jb_ss_inbottom .icon {
        opacity: 0;
    }

    .jb_ss_bottom .jb_ss_inbottom h3 {
        opacity: 0;
    }

    /* ============================= */

    .about_row {
        width: 358px;
        height: 917px;
        flex-direction: column;
        margin-top: 10rem;
    }

    .ab_top_container {
        flex-direction: column;
    }

    .ab_left {
        width: 358px;
        height: 294px;
        border-radius: 20px;
    }

    .underline8 {
        width: 315px;
    }

    .ab_middle {
        flex-direction: row;
        width: 358px;
        height: 151px;
        border-radius: 20px;

        padding: 16px 45px 0px 0px;
        justify-content: space-between;
        align-items: flex-start;
    }

    .underline9 {
        width: 1px;
        height: 100px;
        background: #ffffff;
    }

    .ss_work {
        padding-bottom: 1rem;
    }

    .ab_right {
        flex-direction: row;
    }

    .ab_bottom_container {
        display: flex;
        flex-direction: column;
    }

    .ab_bottom_container .ab_bt_left {
        width: 358px;
        height: 136px;
        border-radius: 20px;
    }

    .ab_bottom_container .ab_bt_right {
        width: 358px;
        height: 136px;
        border-radius: 20px;
    }

    .get_touch_row {
        width: 361px;
        height: 226px;
        flex-direction: column;
        justify-content: space-around;
        margin: -4rem auto;
    }

    .get_touch_row p {
        padding-right: 1rem;
    }

    .get_touch_row button {
        width: 311px;
        height: 46px;
    }

    .slider_container {
        padding: 0 5px;
    }

    .client_feedback_row {
        width: 369px;
        height: 549px;
        flex-direction: column;
    }

    .client_feedback_row .one {
        width: 361px;
        height: 250px;
    }

    .client_feedback_row .clients {
        flex-direction: row;
    }

    .client_feedback_row .clients .client {
        width: 300px;
        height: 273px;
    }

    .hr_effort_row {
        width: 361px;
        height: 418px;
        flex-direction: column;
    }

    .shadow_container2 {
        width: 361px;
        height: 184px;
    }

    .shadow_container2 .hr_left h2 {
        /* font-family: Inter; */
        font-size: 26px;
        font-weight: 500;
        line-height: 31.47px;
        text-align: left;
    }

    .shadow_container2 .hr_left .bold {
        /* font-family: Inter; */
        font-size: 26px;
        font-weight: 700;
        line-height: 31.47px;
        text-align: left;
    }

    .shadow_container2 .hr_left p {
        font-size: 16px;
        font-weight: 400;
        line-height: 18.75px;
        text-align: left;
    }

    .box_shadow3 {
        width: 300px;
        height: 140px;
        box-shadow: 3px 3px 35px rgb(30 168 142 / 54%);
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .hr_effort_row .fl_right_side {
        width: 361px;
        height: 226px;
    }
}

.l-footer {
    z-index: 9 !important;
}

#feedbacks--linear {
    position: relative;

    margin-right: -100px;
    width: 700px;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100px;
        background: linear-gradient(0.25turn, rgba(247, 247, 247, 0), rgba(247, 247, 247, 1));
        z-index: 1;
    }

    .c-feedbacks {
        padding: 0;
        background: none;
    }

    .c-feedbacks--inner {
        padding: 0;
        margin: 0;
        height: auto;

        @media (--min--medium) {
            max-width: 700px;
        }
    }

    .swiper-slide {
        max-width: 296px;
    }

    .c-feedbacks--section {
        max-width: 280px;
        height: 272px;
        margin: 0;
    }

    .c-feedbacks--section-inner {
        height: 272px !important;
        padding: 30px 28px;
        border-radius: 20px;
        box-shadow: none;
    }

    .c-feedbacks--img {
        margin: 0 0 20px;
        width: 50px;
        height: 50px;
    }

    .c-feedbacks--feedback {
        margin: 0 0 20px;
        min-height: 59px;
        line-height: 19px;
        font-weight: 700;
        text-align: left;
        font-family: inherit;
    }

    .c-feedbacks--name {
        font-family: inherit;
        text-align: left;
        font-weight: 400;
        padding: 0;
    }
}

.top_arrow {
    color: #dcdcdc;
}

.hover-section--operator {
    .top_arrow {
        color: #efd1ae;
    }

    .rlv_top {
        .rlv_middle {
            cursor: pointer;
            background-color: #efd1ae;

            p {
                color: #efb489;
            }
            .underline {
                background-color: #efb489;
            }
        }
    }

    .rlv_bottom {
        .rlv_middle {
            .middle_left {
                cursor: pointer;
                background-color: #efd1ae;

                p {
                    color: #efb489;
                }
                .underline3 {
                    background-color: #efb489;
                }
            }
        }
    }
}

.bottom_arrow {
    color: #dcdcdc;
}

.hover-section--manager {
    .bottom_arrow {
        color: #bbd3ef;
    }

    .rlv_top {
        .rlv_right {
            cursor: pointer;
            background-color: #bbd3ef;

            p {
                color: #90b6e4;
            }
            .underline2 {
                background-color: #90b6e4;
            }
        }
    }

    .rlv_bottom {
        .rlv_middle {
            .middle_right {
                cursor: pointer;
                background-color: #bbd3ef;

                p {
                    color: #90b6e4;
                }
                .underline4 {
                    background-color: #90b6e4;
                }
            }
        }
    }
}
