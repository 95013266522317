/* ------------- Component: site-review plugin overwrites ------------- */

.glsr-reviews-wrap {
    margin-bottom: 40px;
}

.glsr-default form.glsr-form.glsr-biuro-form {
    @media (--min--medium) {
        font-size: 15px;
        grid-template-columns: 1fr 1fr;
        line-height: 1.5;
        row-gap: 20px;
        grid-gap: 20px;

        .glsr-field-rating  {
            grid-row: 1 / 2;
            /* grid-column: 1 / 3; */
            grid-column: 1 / 2;
        }
        .glsr-field-textarea {
            grid-row: 1 / 4;
            grid-column: 2 / 3;
        }
        .glsr-field-text {
            grid-row: 2 / 3;
            grid-column: 1 / 2;
        }
        .glsr-field-email {
            grid-row: 3 / 4;
            grid-column: 1 / 2;
        }

        .glsr-field-choice {
            grid-row: 4 / 5;
            grid-column: 1 / 3;
        }

        [data-field="submit-button"] {
            grid-row: 6 / 7;
        }

        textarea {
            min-height: 208px;
        }
    }
}

.glsr-default form.glsr-form.glsr-biuro-form {

    .glsr-field {
        margin-bottom: 8px;
    }

    .glsr-toggle label,
    .glsr-label {
        color: #2a3644;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 4px;
        font-weight: 500;
    }

    .glsr-toggle label {
        margin: 2px 0 8px 10px;
    }

    .glsr-toggle-track {
        width: 38px !important;
        height: 22px !important;

        &:after {
            width: 16px !important;
            height: 16px !important;
        }
    }



    [data-star-rating]>select:focus+.gl-star-rating--stars:before {
        box-shadow: 0 0 0 1px #000;
    }

    .glsr-toggle input[type=checkbox]:checked+.glsr-toggle-track:after {
        transform: translateX(16px);
    }

    .glsr-toggle input[type=checkbox]:focus+.glsr-toggle-track {
        box-shadow: 0 0 0 1px #006957;
    }
    .glsr-toggle input[type=checkbox]:checked+.glsr-toggle-track:before {
        background-color: #149a83;
    }

    .glsr-input,
    .glsr-textarea {
        width: 100%;
        padding: 10px;
        background: none;
        border: 1px solid #d4d4d4;
        border-radius: 3px;
        font-size: 15px;
        font-family: inherit;

        &:focus {
            border-color: #000;
            outline: none;
            box-shadow: none;
        }
    }

    .glsr-field-error,
    .glsr-form-message  {
        font-size: 13px;
        line-height: 20px;
        padding-top: 2px
    }

    .glsr-field-error,
    .glsr-form-failed {
        color: #eb4646;
    }

    [type=submit] {
        display: inline-block;
        border: 0;
        padding: 11px 24px 10px;
        cursor: pointer;
        border-radius: 3px;
        font-weight: 500;
        line-height: 15px;
        font-size: 15px;
        text-decoration: none;
        font-family: inherit;
        color: #fff;
        background: #1fb299;
        border: 2px solid #1fb299;

        &:hover {
            background: #149a83;
            border-color: #149a83;
        }
    }
}
