/* ------------- Component: btn ------------- */

/* critical:start */

.c-btn--header { font-size: 14px; font-weight: 500;
    @media (--min--medium) {
        margin-right: 28px;
    }
}

.c-btn--footer { display: block; text-align: center; }

.c-btn--main { color: #fff; background: var(--color--green); border: 2px solid var(--color--green);
    &:hover { background: var(--color--green-darker); border-color: var(--color--green-darker); }
}

.c-btn--phone { color: #fff; background: var(--color--green); border: 2px solid var(--color--green); border-radius: 44px; overflow: hidden; height: 44px; line-height: 24px; margin: -15px 0 15px; padding: 9px 15px 8px;
    svg { float: left; margin: 0 10px 0 0; }
    &:hover { background: var(--color--green-darker); border-color: var(--color--green-darker); }
}

.c-btn--phone-alt {
    color: #fff;
    background: #DA2128;
    border: 2px solid #DA2128;
    overflow: hidden;
    height: 44px;
    line-height: 24px;
    margin: -10px 0 15px;
    padding: 9px 15px 8px;

    svg {
        float: left;
        margin: 0 20px 0 0;
    }

    &:hover {
        background: #A70000;
        border-color: #A70000;
    }

    @media (--min--medium) {
        height: 68px;
        margin: 10px 0 35px;
        padding: 21px 28px 20px;
    }
}

.c-btn--disabled { color: #bbb; background: #6f7479; border-color: #6f7479; outline: none; pointer-events: none; user-select: none;
    &:hover { color: #bbb; background: #6f7479; cursor: not-allowed; border-color: #6f7479;}
}

.c-btn--vdb { color: #fff; background: #ff7046; border-color: #ff7046;
    &:hover { background: #ff501d; border-color: #ff501d; }
}

.c-btn--alt     { padding: 10px 15px;  color: #004ED4;  background: #e8f0ff; color: #004ED4;
    &:after { float: right; margin: 6px 0 0 35px; content: ''; width: 7px; height: 7px; border: 1px solid #004ED4; border-width: 2px 2px 0 0; transform: rotate(45deg); }
    &:hover     { background-color: #DDE6F5;  }
}

.c-btn--fill { width: 100%; }

.c-btn--fit { flex: 1 1 20%;
    + .c-btn--fit { margin-left: 20px; }
}

.c-btn--max-fill {
    width: 100%;
    max-width: 320px;
}

.c-btn--search {
    min-width: 180px;
    border-radius: 0 3px 3px 0;
    text-align: center;

    @media (--max--medium) {
        flex: 0 0 60px;
        border-radius: 0 0 3px 3px;
    }
}

.c-btn--search-small {
    margin: 9px;
    min-width: 133px;
    text-align: center;
}

.c-btn--slim {
    color: #14a28a;
    background: none;
    text-align: center;
    border: 2px solid #1fb299;

    &:hover {
        border-color: var(--color--green-darker);
        background-color: var(--color--green-darker);
        color: #fff;
    }
}

.c-btn--filter {
    color: #2a3644;
    font-size: 15px;
    line-height: 26px;
    text-align: center;
    background: #fff;
    border-radius: 3px;
    padding: 6px 10px 6px 7px;

    @media (--min--xx-small) {
        padding: 6px 15px 6px 12px;
    }
}

/* critical:end */

.c-btn--popup {
    width: 100%;
    padding: 13px 24px 12px;
    background-color: #fff;
    color: #3eb0b1;
    font-size: 15px;
    font-weight: bold;
    text-align: center;

    @media (--min--x-small) {
        max-width: 223px;
    }
}

.c-btn--newsletter {
    padding-top: 15px;
    padding-bottom: 14px;

    @media (--max--medium) {
        width: 100%;
        margin-top: 13px;
    }
}

.c-btn--loading {
    position: relative;

    &::before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
        border-radius: 50%;
        border: 2px solid #fff;
        border-top-color: var(--color--green);
        animation: spinner .8s linear infinite;
    }
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}
