/* ------------- Component: form ------------- */

/* critical:start */


.c-form { position: relative }

.c-form--header {
    color: #069980;
    font-size: 21px;
    font-weight: 700;
    margin: -8px 0 20px;
    padding: 0;

    @media (--min--medium) {
        font-size: 24px;
    }
}

.c-form--action { position: absolute; top: -88px; left: -9999px; width: 1px;
    @media (--min--medium) {
        top: -128px;
    }
}

.c-form--action-position { top: -58px; }

.c-form--employees,
.c-form--employers { padding: 28px 20px 20px; border-radius: 3px; background-color: #ffffff; box-shadow: 0 1px 31px -23px #6A7481;
    @media (--min--medium) {
        padding: 38px 35px 20px;
    }
}

.c-form--position { padding: 28px 20px 20px; border-radius: 3px; background-color: #ffffff;
    @media (--min--medium) {
        margin: -40px -15px 20px; box-shadow: 0 1px 31px -23px #6A7481;
    }
}

.c-form--recommend { border-radius: 3px; background-color: #ffffff; box-shadow: 0 1px 31px -23px #6A7481; padding: 0 15px 20px;
    @media (--min--medium) {
        padding: 0 33px 20px;
    }
}

.c-form--cols   { margin: 0 -15px;
    @media (--min--medium) {
         display: flex; margin: 0 -33px;
     }
 }

.c-form--col    { flex: 1 1 50%; padding: 25px 15px 0; }

.c-form--col--about              { background: linear-gradient(180deg, #A1FFF0 0%, rgba(255,255,255,0) 168px);
    @media (--min--medium) {
        padding: 42px 24px 0 33px;
    }
}
.c-form--col--about-friend   { background: linear-gradient(180deg, #CFE1FC 0%, rgba(255,255,255,0) 168px);
    @media (--min--medium) {
        padding: 42px 24px 0 33px;
    }
}

.c-form--success { margin: -28px -20px 20px; padding: 28px 20px 20px; border-radius: 3px 3px 0 0; text-align: center; color: #27b199; line-height: 25px; background: #e5fdf9; font-weight: 500;
    @media (--min--medium) {
        margin: -38px -35px 20px; padding: 28px 35px 20px;
    }
}

/*.c-form--success-recommend { margin: 0 -15px;
    @media (--min--medium) {
        margin: 0 -33px;
    }
}*/

.c-form--headline { text-transform: uppercase; margin-bottom: 20px; font-weight: 500; font-size: 18px;
    @media (--min--medium) {
        margin-bottom: 30px;
    }
}
.c-form--headline--about { color: #14B399; }
.c-form--headline--about-friend { color: #004ED7; }

.c-form--row { position: relative; margin-bottom: 20px; }

.c-form--row-sticky { display: flex;
    @media (--max--medium) {
        position: fixed; right: 0; bottom: 0; left: 0; margin: 0; padding: 15px 23px; background: #fff; box-shadow: 0 1px 39px -23px #454C54; z-index: 90;
    }
    .o-btn {
        &:nth-child(n+2) {
            @media (--min--medium) {
                display: none;
            }
        }
    }
}

.c-form--label { display: block; color: #2A3644; font-size: 15px; line-height: 18px; margin-bottom: 4px; font-weight: 500; }

.c-form--input-wrap { }
.c-form--input-file-wrap { position: relative; overflow: hidden; }


.c-form--input { width: 100%; padding: 10px; background: none; border: 1px solid #d4d4d4; border-radius: 3px; font-size: 15px; font-family: inherit;
    &:focus { border-color: #000; outline: none; }
}

.c-form--textarea { width: 100%; padding: 10px; background: none; border: 1px solid #d4d4d4; border-radius: 3px; font-size: 15px; font-family: inherit;
    &:focus { border-color: #000; outline: none; }
}

.c-form--input-file { position: absolute; left: 0; top: 0; width: 100%; height: 100%; cursor: pointer; z-index: 10; opacity: 0;
    &:hover {
        + button { background: var(--color--green); color: #fff; }
    }
    &:focus {
        & + .c-form--input-file-btn {
            border-color: #000;
        }
    }
}
.c-form--input-file-btn { width: 100%; padding: 10px 10px 6px; background-color: #F0F0F0; border: 1px solid #F0F0F0; border-radius: 3px; font-size: 15px; font-family: inherit; text-align: center;
    &:focus { border-color: #000; outline: none; }
}

.c-form--input-file-text { display: inline-block; max-width: 200px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }


.c-form--submit-wrap { padding: 10px 0; }

.c-form--checkbox-wrap { display: flex; position: relative; }
.c-form--checkbox   { position: absolute; top: 0; left: 0; visibility: hidden;
    &:checked {
        + label {
            &:before { border-color: #d4d4d4; }
            &:after { content: '✔'; }
        }
    }
}
.c-form--label-checkbox { position: relative; margin: 0 0 0 31px; color: #6f7479; font-size: 12px; line-height: 17px;
    &:before { content: ''; position: absolute; top: 0; left: -31px; height: 18px; width: 18px; border: 1px solid #d4d4d4; border-radius: 3px; cursor: pointer; }
    &:after { content: '';     position: absolute; top: -2px; left: -28px; width: 20px; height: 20px; border-radius: 3px; color: var(--color--green); font-size: 22px; cursor: pointer; }
}

.c-form--label-infobox { color: #6f7479; font-size: 12px; line-height: 17px; }
.c-form--label-recaptcha { color: #6f7479; font-size: 12px; line-height: 17px; }

/* critical:end */

.c-form--checkbox--error {
    + label {
        &:before { border-color: #EB4646; }
    }
}

/* critical:start */

.c-form--validation {  background-color: #ffffff; margin-top: 5px; padding: 10px 15px; border: 1px solid; font-size: 13px; line-height: 20px; z-index: 20;
    @media (--min--medium) {
        position: absolute; top: 50%; right: 100%; min-width: 200px; margin-top: 11px; transform: translate(-30px, -50%);
    }
    @media (--min--xxx-large) {
        left: 100%; right: auto; transform: translate(30px, -50%);
    }
}

/* critical:end */

.c-form--validation-fixed { background-color: #ffffff; margin-top: 5px; padding: 10px 15px; border: 1px solid; font-size: 13px; line-height: 20px; }
.c-form--validation-static { background-color: #ffffff; margin-top: -10px; padding: 10px 15px; border: 1px solid; line-height: 20px; font-size: 14px; }



.c-form--input-wrap--error {
    .c-form--input,
    .c-form--textarea {
        border-color: #eb4646;
    }
}

.c-form--input-wrap--success {
    .c-form--input,
    .c-form--textarea {
        border-color: #17dab9;
    }
}

.c-form--validation-error { color: #eb4646; border-color: #eb4646; margin-bottom: 10px; }
.c-form--validation-success { color: #17dab9; border-color: #17dab9; margin-bottom: 10px; }

.c-form--autocomplete {
    ul { overflow-x: hidden; max-height: 250px; overflow-y: auto; border: 1px solid #d4d4d4; border-radius: 0 0 3px 3px; }
    li  { padding: 5px 10px; cursor: pointer; }
}

.c-form--row--content {
    overflow: hidden;
}

.c-form--row--toggle {
    position: relative;
    top: -4px;
    width: 37px;
    height: 37px;
    margin: 0 auto -16px;
    box-shadow: 0 2px 12px 0 rgba(146,141,141,.5);
    background-color: #fff;
    border-radius: 50%;
    color: #004ed4;
    cursor: pointer;
}

.c-form--ico-toggle-up {
    display: block;
    position: relative;
    top: 13px;
    margin: 0 auto;
}

.c-form--ico-toggle-down {
    display: none;
    position: relative;
    top: 15px;
    margin: 0 auto;
}

.c-form--row--collapsed {
    .c-form--row--content {
        height: 94px;
    }

    .c-form--row--toggle {
        top: -20px;
        margin-bottom: -32px;
    }

    .c-form--ico-toggle-down { display: block; }

    .c-form--ico-toggle-up { display: none; }
}
