/* ------------- Component: jobs list ------------- */

/* critical:start */

.c-jobs {  }

.c-jobs--ua {
    margin-bottom: 38px;
}

.c-jobs--inner {
    @extend .l-inner-small;
}

.c-jobs--info {
    max-width: 626px;
    margin: 0 auto;
    padding: 40px 20px 0;
    text-align: center;
    color: #64696D;

    a {
        color: #0245CE;
    }
}

.c-jobs--inner-custom { max-width: 990px; margin-right: auto; margin-left: auto; padding-right: 10px; padding-left: 10px; }

.c-jobs--table {
    width: 100%;
    margin: 0 0 20px;
    padding: 0;
}

.c-jobs--headline {
    margin: 0 0 25px;
    padding: 20px 20px 0;

    @media (--max--medium) {
        text-align: center;
    }
}

/* critical:end */

.c-jobs--headline {
    min-height: 29px;
    color: #2a3644;
    font-size: 25px;
    font-weight: 500;
    line-height: 29px;

    @media (--max--medium) {
        min-height: 26px;
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        text-align: center;
    }
}

.c-jobs--table {
    tr {
        &:hover {
            td { background: #f8fbff; }
        }
    }
}

/* critical:start */

.c-jobs--col {
    background: #fff;
    vertical-align: middle;
    color: #6f7479;
    padding: 10px 15px;

    @media (--max--medium) {
        float: left;
    }

    @media (--min--medium) {
        border-bottom: 1px solid var(--color--gray-light);
    }
}

.c-jobs--col-position {
    @media (--max--medium) {
        width: 100%;
    }
}

.c-jobs--col-description {
    @media (--max--medium) {
        width: 100%;
        padding-top: 0;
    }
}

.c-jobs--col-valid,
.c-jobs--col-city {
    @media (--max--medium) {
        width: 50%;
    }
}

/* critical:end */

.c-jobs--col-position {
    @media (--min--medium) {
        padding-right: 5px;
    }
}

.c-jobs--col-city {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (--max--medium) {
        border-bottom: 1px solid var(--color--gray-light);
        font-size: 13px;
        padding-top: 0;
    }

    @media (--min--medium) {
        max-width: 140px;
        padding-right: 5px;
        padding-left: 5px;
    }
}

.c-jobs--col-valid { color: #069980; white-space: nowrap; padding-right: 15px; padding-left: 5px; width: 100px; text-align: right;
    @media (--max--medium) {
        width: 50%; border-bottom: 1px solid var(--color--gray-light); font-size: 13px; padding-top: 0; text-align: right;
    }
}
.c-jobs--col-salary { color: #069980; white-space: nowrap; padding-right: 15px; padding-left: 5px; width: 140px; text-align: right;
    @media (--max--medium) {
        width: 50%; border-bottom: 1px solid var(--color--gray-light); font-size: 13px; padding-top: 0; text-align: right;
    }
}

/* critical:start */

.c-jobs--anchor {
    display: block;
    color: #004ed4;
    font-weight: 500;
    line-height: 18px;
    text-decoration: none;

    @media (--min--medium) {
        display: flex;
        min-height: 38px;
        justify-content: center;
        flex-direction: column;
    }
}

.c-jobs--salary {
    display: block;
    color: #6f7479;
    padding: 4px 0;
    font-weight: 400;
    font-size: 14px;
}

.c-jobs--anchor-alt {
    display: block;
    color: #2a3644;
    line-height: 18px;
    text-decoration: none;
}

/* critical:end */

.c-jobs--anchor {

    @media (--max--medium) {
        padding: 4px 0;
        font-size: 16px;
        line-height: 23px;
    }


    &:hover { text-decoration: underline; }
    &:visited { color: #551b89;  }
}

.c-jobs--anchor-alt {
    &:hover {
        text-decoration: underline;
    }
}

/* critical:start */

.c-jobs--more {
    margin-bottom: 50px;

    @media (--min--medium) {
        margin-bottom: 70px;
    }
}

/* critical:end */


.c-jobs--row-workis {
    td {
        @media (--max--medium) {
            min-height: 32px;
        }
        @media (--min--medium) {
            border-top: 1px solid #AACAFF;
            border-bottom: 1px solid #AACAFF;
        }
    }

    .c-jobs--col-position {
        border-top: 1px solid #AACAFF;
        border-left: 1px solid #AACAFF;

        @media (--max--medium) {
            border-right: 1px solid #AACAFF;
        }
    }

    .c-jobs--col-city {
        border-bottom: 1px solid #AACAFF;

        @media (--max--medium) {
            border-left: 1px solid #AACAFF;
        }
    }

    .c-jobs--col-valid {
        border-right: 1px solid #AACAFF;
        border-bottom: 1px solid #AACAFF;
    }
}

.c-jobs--workis-picture {
    display: block;
    height: 21px;
}
