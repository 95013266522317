/* ------------- Component: popup ------------- */

/* critical:start */

.c-popup {
    display: none;
}

/* critical:end */

.c-popup {
    background: rgba(0, 0, 0, .77);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9000;
}

.c-popup--close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 13px 16px;
    color: #1c1c1c;
    cursor: pointer;

    &:hover {
        color: #000;
    }
}

.c-popup--inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    max-width: 96%;
    max-height: 96%;
    margin: 0 auto;
    background-repeat: no-repeat;
}

.c-popup--inner-employee {
    background-color: #3eb0b1;
    background-position: 16px 90%;
    background-size: 200px;
    color: #fff;
    justify-content: space-around;
    align-items: center;
    width: 786px;
    padding: 20px;

    @media (max-height: 24em) {
        background-image: none !important;
    }

    @media (--min--x-small) {
        height: 446px;
        flex-direction: row;
        background-position: 20px 0;
        background-size: auto;
    }
}

.c-popup--inner-employer {
    background-color: #d3ebdd;
    background-position: 76px 100%;
    background-size: 123px;
    color: #043d7d;
    align-items: flex-start;
    width: 276px;
    height: 434px;
    padding: 60px 40px 20px 20px;

    @media (--min--x-small) {
        width: 668px;
        height: 340px;
        padding: 50px 40px 20px;
        background-position: 315px 100%;
        background-size: 218px;
    }
}

.no-js,
.js.no-webp {
    .c-popup--inner-employee { background-image: url(/wp-content/themes/biuro/i/recommend.png); }
    .c-popup--inner-employer { background-image: url(/wp-content/themes/biuro/i/workis-board.png); }
}

.js.webp {
    .c-popup--inner-employee { background-image: url(/wp-content/themes/biuro/i/recommend.webp); }
    .c-popup--inner-employer { background-image: url(/wp-content/themes/biuro/i/workis-board.webp); }
}

.c-popup--logos {
    padding-top: 60px;

    @media (max-height: 24em) {
        display: none;
    }

    @media (--max--x-small) {
        order: 2;
        width: 100%;
    }

    @media (--min--x-small) {
        flex: 0 1 303px;
        padding-top: 160px;
    }
}

.c-popup--content {
    font-size: 13px;
    line-height: 15px;

    @media (--max--x-small) {
        order: 1;
    }

    @media (--min--x-small) {
        flex: 0 1 303px;
    }
}

.c-popup--amount {
    position: relative;
    bottom: -16px;
    width: 78px;
    height: 78px;
    background: #004ed7;
    opacity: 0.85;
    color: #fff;
    font-size: 17px;
    line-height: 20px;
    font-weight: bold;
    letter-spacing: 0.17px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 auto;

    @media (--min--x-small) {
        margin: 0 auto;
        left: 31px;
        width: 108px;
        height: 108px;
        font-size: 22px;
        line-height: 25px;
    }
}

.c-popup--gift {
    position: relative;
    margin: 0 43px 0 auto;
    width: 50px;
    height: 50px;
    box-shadow: 0 2px 12px 0 rgba(146,141,141,.5);
    background-color: #fff;
    border-radius: 50%;
    color: #004ed4;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (--min--x-small) {
        margin: 0 auto;
        width: 70px;
        height: 70px;
    }
}

.c-popup--headline {
    font-size: 28px;
    line-height: 33px;
    color: inherit;

    @media (--min--x-small) {
        margin-bottom: 56px;
    }
}

.c-popup--message {
    font-size: 15px;
    line-height: 24px;

    @media (--min--x-small) {
        margin-bottom: 24px;
    }
}

.c-popup--headline-employer {
    margin: 0 0 20px;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;

    @media (--min--x-small) {
        max-width: 270px;
        margin: 0 0 26px;
        font-size: 26px;
        line-height: 34px;
    }
}

.c-popup--description-employer {
    margin: 0 0 20px;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;

    @media (--min--x-small) {
        max-width: 270px;
        margin: 0 0 60px;
        font-size: 14px;
    }
}

.c-popup--button-employer {
    display: flex;
    padding: 12px 24px 10px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    background: #7ac943;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;

    @media (--min--x-small) {
        font-size: 18px;
        line-height: 21px;
    }
}

.c-popup--workis {
    position: absolute;
    right: 30px;
    bottom: 30px;

    @media (--max--x-small) {
        display: none;
    }
}
