/* ------------- Component: divisions ------------- */

/* critical:start */

.c-divisions    { display: flex; flex-wrap: wrap;
    @media (--max--medium) {
        margin-bottom: 20px;
    }
}

.c-division { flex: 0 0 auto; height: 30px; margin: 0 10px 10px 0; padding: 0 20px; background-color: #E8F0FF; line-height: 30px; white-space: nowrap; }

/* critical:end */

.c-division { user-select: none; cursor: pointer; border-radius: 3px;  color: #004ED4; font-weight: 500;
    &:hover     { background-color: #DDE6F5; }
}
