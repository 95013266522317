/* ------------- Layout: map ------------- */

/* critical:start */

.l-map {
    height: 330px;
    margin-bottom: 35px;
}

.l-map--cities {
    height: 625px;
}

.l-map--cities--panevezys-inner {
    height: 305px;

    @media (--min--medium) {
        height: 513px;
    }
}

/* critical:end */

.l-map--cities--panevezys {
    position: relative;

    @media (--min--medium) {
        margin: 40px 0 0;
    }
}

.l-map--cities--panevezys-aside {
    display: flex;
    flex-direction: column;
    padding: 15px 30px;
    background: rgba(218, 33, 40, 0.9);
    z-index: 10;
    justify-content: center;

    @media (--min--medium) {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 460px;
        padding: 20px 52px 50px;
    }
}

.l-map--cities--panevezys-wrap {
    display: flex;
    padding: 0 0 15px;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #fff;

    @media (--min--medium) {
        flex-direction: column;
        align-items: start;
        margin: 0 0 40px;
        padding: 36px 40px 24px 0;
    }

    svg {
        margin: 0 0 30px;
        @media (--max--medium) {
            width: 26px;
            height: 27px;
            margin: 0 25px 0 0;
            flex: 0 0 26px
        }
    }

    h2 {
        margin: 0;
        color: #fff;
        font-weight: bold;
        font-size: 18px;
        line-height: 26px;

        @media (--min--medium) {
            font-size: 30px;
            line-height: 40px;
        }
    }
}

.l-map--cities--panevezys-svg {
    @media (--max--medium) {
        margin: 10px auto;
        width: 150px;
    }
}
